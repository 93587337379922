import React, { useState } from "react";
import { useGeolocated } from "react-geolocated";
import { json } from "react-router-dom";

const LocationComponent = () => {
  const [latitude2, setLatitude2] = useState("");
  const [longitude2, setLongitude2] = useState("");
  const { isGeolocationAvailable, isGeolocationEnabled, coords } =
    useGeolocated();

  if (!isGeolocationAvailable || !isGeolocationEnabled) {
    // Geolocation is not available or enabled
    return <div>Geolocation is not available or enabled</div>;
  }
  console.log(coords);

  const latitude = coords?.latitude;
  const longitude = coords?.longitude;
  if (!(latitude && longitude)) {
    // Geolocation is not available or enabled
    console.log("not available");
  }

  const getCurrenttLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude2(position.coords.latitude);
          setLongitude2(position.coords.longitude);
          //   setAccuracy(position.coords.accuracy);
          console.log(position.coords);
        },
        (error) => {
          console.log("Error:", error.message);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 15000,
          timeout: 12000,
        }
      );

      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          setLatitude2(position.coords.latitude);
          setLongitude2(position.coords.longitude);
        },
        (error) => {
          console.log("Error:", error.message);
        },

        {
          enableHighAccuracy: true,
          maximumAge: 15000,
          timeout: 12000,
        }
      );

      // Clear the watch position no longer need it
      // navigator.geolocation.clearWatch(watchId);
    } else {
      console.log("Geolocation API is not supported");
    }
  };
  getCurrenttLocation();
  return (
    <div>
      <h1>Current Location:</h1>
      <p>Latitude: {latitude}</p>
      <p>Longitude: {longitude}</p>
      <br />
      <br />
      <p>Latitude: {latitude2}</p>
      <p>Longitude: {longitude2}</p>
    </div>
  );
};

export default LocationComponent;
