import React from "react";
import customerLogo from "../assets/customer-service.png";
// import crossIcon from "../assets/cross-icon.png";
import closeIcon from "../assets/close.svg";
import { NavLink } from "react-router-dom";

function HelpModal({ handleModal }) {
  return (
    <div className="w-[300px] h-[200px] flex justify-center gap-5 items-center bg-[#fff] rounded-xl shadow-lg">
      <img src={customerLogo} alt="logo" className="w-20" />
      <a href="tel:8527199811">
        <h1 className="text-start font-semibold">
          Click here <br /> To Call Support Cell <br />
          8527199811
        </h1>
      </a>

      <img
        src={closeIcon}
        alt="cross-logo"
        className="absolute -right-2 -top-[10px] w-7 bg-[#FF971A] rounded-3xl p-1"
        onClick={handleModal}
      />
    </div>
  );
}

export default HelpModal;
