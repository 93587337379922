import React, { useState, useEffect } from "react";
import leftArrow from "../../assets/leftArrow.png";

import handlogo from "../../assets/handlogo.png";
import stanvacLogo from "../../assets/stanvaclogo.png";
import callIcon from "../../assets/call1.svg";

import PhoneInputField from "./PhoneInputField";
import OtpInput from "./OtpInput";
import config from "../Utils/config";
import axios from "axios";
import HelpModal from "../HelpModal";
import { useNavigate } from "react-router-dom";
// import callIcon from "../../assets/call2.svg";

function PhoneInputMobile() {
  const navigate = useNavigate();
  // handle user
  const handleUser = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    if (phoneNum) {
      const { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);

      localStorage.setItem("superonUserName", data?.data?.name);
      console.log(data);

      // localStorage.setItem("superonUserName", data.data.name);

      if (data.data.status === "0") {
        navigate("/pending");
      }
      if (data.data.status === "1") {
        navigate("/createprofile");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      navigate("/phone");
    }
  };

  useEffect(() => {
    // handleUser();
  }, []);

  // blockUser
  useEffect(() => {
    let phoneNum = localStorage.getItem("superonUserPhNum");

    if (!phoneNum) {
      return navigate("/phone");
    } else {
      handleUser();
    }
  }, []);

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [userCredentials, setUserCredentials] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState({
    first: "+91",
    second: "",
  });
  const handlePhoneSubmit = async () => {
    let phoneNum = phoneNumber.second;
    console.log(phoneNum);
    // console.log(config);
    if (phoneNum.length !== 10) return;

    let { data } = await axios.post(config.ipAddress + "/auth/otp/send", {
      cellphoneNumber: phoneNum,
    });
    console.log(data.data);
    setUserCredentials(data.data);

    if (data.data.approved === 2) {
      // handleUser();
      // navigate("/dashboard/target");
    }
    setIsOtpSent(true);
  };
  const goBackToPhoneInputField = () => {
    setIsOtpSent(false);
  };

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-[#fafafa] h-[100dvh]">
      <div className="flex justify-between items-center py-5 px-5 bg-blue-light">
        {isOtpSent ? (
          <img
            src={leftArrow}
            alt="left-arrow"
            className="h-5"
            onClick={() => goBackToPhoneInputField()}
          />
        ) : (
          <div className="w-[21.7px]"></div>
        )}
        <img src={stanvacLogo} alt="stanvac-logo" className="w-[100px]" />

        <a href="tel:8527199811">
          <img
            src={callIcon}
            alt="left-arrow"
            className="w-6"
            // onClick={() => setIsModalOpen(true)}
          />
        </a>
      </div>
      <div className="h-[83vh] ">
        {/* <img
          src={handlogo}
          alt="hand-logo"
          className="w-16 ml-6 absolute -top-9"
        /> */}

        <div className="h-full">
          {isOtpSent ? (
            <OtpInput userCredentials={userCredentials} />
          ) : (
            <PhoneInputField
              handlePhoneSubmit={handlePhoneSubmit}
              number={{ phoneNumber, setPhoneNumber }}
            />
          )}
        </div>
      </div>

      {isModalOpen ? (
        <div
          className="absolute z-20  text-center top-[20%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          onBlur={() => setIsModalOpen(false)}
        >
          <HelpModal handleModal={handleModal} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div className="w-full h-full bg-slate-300 absolute top-0 opacity-30 z-[1]"></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PhoneInputMobile;
