import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  Polyline,
  useJsApiLoader,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
// import markerIcon from "../../assets/locationCircleIcon (2).svg";
// import visitMarker from "../../assets/visitMarker.svg";
import visitMarker from "../../assets/blueMarker.svg";
import markerIcon from "../../assets/locationIconAnimated.gif";
import crossIcon from "../../assets/cross.svg";
import currentLocationIcon from "../../assets/currentLocationIcon.svg";
import config from "../Utils/config";
import axios from "axios";

import useCurrentLocations from "../customHook/useCurrentLocations";

const userLocations = [
  { lat: 22, lng: 88 },
  { lat: 23, lng: 89 },
  { lat: 24, lng: 89.342 },
  { lat: 24.22, lng: 89.232 },
  { lat: 24.53, lng: 89.53 },
  { lat: 24.69, lng: 89.6 },
];

const markerData = [
  // Your array of marker data here
  {
    lat: 22,
    lng: 88,
    name: "Marker 1",
    description: "This is marker 1.",
  },
  {
    lat: 23,
    lng: 89,
    name: "Marker 2",
    description: "This is marker 2.",
  },
  // Add more marker data as needed
];
const GoogleMapRoutes = () => {
  const phoneNum = localStorage.getItem("superonUserPhNum");

  const [data, visitLocations, markerData] = useCurrentLocations();
  console.log(visitLocations, data, markerData);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  // const center = {
  //   lat: 28.6166471,
  //   lng: 77.2870439,
  // };

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [travelPositions, setTravelPositions] = useState([]);
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  const [directions, setDirections] = useState([]);
  const [distance, setDistance] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleApiKey,
    // libraries: ["places"],
  });

  //   get location
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });

  const getCurrenttLocation = () => {
    let location = navigator.geolocation.getCurrentPosition((position) => {
      if (map) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    });

    return location;
  };

  function watchUserLocation() {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000,
    };

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        if (map) {
          setLocation({
            lat: latitude,
            lng: longitude,
          });
        }

        console.log(latitude, longitude);

        // Pan the map to the new location
        // map.panTo({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error watching user's location: ", error);
      },
      options
    );

    // Return the watchId so that it can be used to clear the watch later if needed.
    return watchId;
  }

  const onMarkerClick = (marker) => {
    // console.log("clicked", marker);
    setSelectedMarker(marker);
  };

  const onCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  // const calculateDistance = (lat1, lng1, lat2, lng2) => {
  //   const startPoint = new window.google.maps.LatLng(lat1, lng1);
  //   const endPoint = new window.google.maps.LatLng(lat2, lng2);
  //   return window.google.maps.geometry.spherical.computeDistanceBetween(
  //     startPoint,
  //     endPoint
  //   );
  // };
  const locations = [
    { lat: 28.6315, lng: 77.2167 }, // Connaught Place
    { lat: 28.6129, lng: 77.2295 }, // India Gate
    { lat: 28.6562, lng: 77.241 }, // Red Fort (Lal Qila)
    { lat: 28.5535, lng: 77.2588 }, // Lotus Temple
    { lat: 28.5245, lng: 77.1855 }, // Qutub Minar
    { lat: 28.6127, lng: 77.2773 }, // Akshardham Temple
    { lat: 28.5933, lng: 77.2507 }, // Humayun's Tomb
  ];

  useEffect(() => {
    if (isLoaded) {
      setPolylineCoordinates(data);
      const locations = [
        { lat: 28.6315, lng: 77.2167 }, // Connaught Place
        { lat: 28.6129, lng: 77.2295 }, // India Gate
        { lat: 28.6562, lng: 77.241 }, // Red Fort (Lal Qila)
        { lat: 28.5535, lng: 77.2588 }, // Lotus Temple
        { lat: 28.5245, lng: 77.1855 }, // Qutub Minar
        { lat: 28.6127, lng: 77.2773 }, // Akshardham Temple
        { lat: 28.5933, lng: 77.2507 }, // Humayun's Tomb
      ];

      if (data.length) {
        const directionsService = new window.google.maps.DirectionsService();
        const waypointsPerRequest = 25;

        const processWaypoints = (waypoints) => {
          const origin = waypoints[0].location;
          const destination = waypoints[waypoints.length - 1].location;

          directionsService.route(
            {
              origin,
              destination,
              waypoints: waypoints.slice(1, waypoints.length - 1),
              optimizeWaypoints: true,

              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections((prev) => [...prev, result]);
                // Store the entire DirectionsResult in the directions state
              }
            }
          );
        };

        const waypoints = data.map((location) => ({
          location: new window.google.maps.LatLng(location.lat, location.lng),
          stopover: true,
        }));

        const splitWaypoints = (waypoints) => {
          for (let i = 0; i < waypoints.length; i = i + waypointsPerRequest) {
            console.log(i);
            const groupWaypoints = waypoints.slice(i, i + waypointsPerRequest);
            processWaypoints(groupWaypoints);
          }
        };

        // Split the waypoints into groups and process each group in a separate request
        splitWaypoints(waypoints);
      }
    }
  }, [isLoaded, data]);

  // useEffect(() => {
  //   if (isLoaded) {
  //     setPolylineCoordinates(data);

  //     if (data.length) {
  //       const directionsService = new window.google.maps.DirectionsService();
  //       const waypoints = data.map((location) => ({
  //         location: new window.google.maps.LatLng(location.lat, location.lng),
  //         stopover: true,
  //       }));

  //       const origin = waypoints.shift()?.location;
  //       const destination = waypoints.pop()?.location;
  //       console.log(origin, destination);

  //       directionsService.route(
  //         {
  //           origin,
  //           destination,
  //           waypoints,
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         },
  //         (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //             setDirections(result); // Set the directions state here
  //           }
  //         }
  //       );
  //     }
  //   }
  // }, [isLoaded, data]);

  useEffect(() => {
    console.log(directions);
  }, [directions]);

  const submitCurrentLocation = async () => {
    let data = {
      mobile: phoneNum,
      lat: location.lat,
      lang: location.lng,
    };

    if (!(location.lat && location.lng)) return;

    try {
      let response = await axios.post(
        config.ipAddress + `/position/currentposition`,
        data
      );
      console.log(response);
    } catch (error) {}
  };

  useEffect(() => {
    // Check if the map object is available
    // submitCurrentLocation();

    if (map) {
      // Render the marker when the map is available
      const marker = new window.google.maps.Marker({
        icon: markerIcon,
        scale: 0.01,
        position: location,
        map: map,
      });
      // Cleanup the marker when the component is unmounted
      return () => {
        marker.setMap(null);
      };
    }
  }, [map, location]);

  useEffect(() => {
    // Check if the map object is available
    // submitCurrentLocation();
    // setPolylineCoordinates(travelPositions);
    // Render the marker when the map is available
    //   let visitLocations = [
    //     { lat: 23, lng: 88 },
    //     { lat: 23.22, lng: 89 },
    //     { lat: 24.22, lng: 89.342 },
    //     { lat: 24.22356, lng: 89.232 },
    //     { lat: 25.53, lng: 89.53 },
    //     { lat: 25.69, lng: 89.6 },
    //   ];
    const locations = [
      { lat: 28.6315, lng: 77.2167 }, // Connaught Place
      { lat: 28.6129, lng: 77.2295 }, // India Gate
      { lat: 28.6562, lng: 77.241 }, // Red Fort (Lal Qila)
      { lat: 28.5535, lng: 77.2588 }, // Lotus Temple
      { lat: 28.5245, lng: 77.1855 }, // Qutub Minar
      { lat: 28.6127, lng: 77.2773 }, // Akshardham Temple
      { lat: 28.5933, lng: 77.2507 }, // Humayun's Tomb
    ];
    if (map && markerData.length) {
      const markers = markerData.map((marker) => {
        let lat = Number(marker.lat);
        let lng = Number(marker.lng);
        const newMarker = new window.google.maps.Marker({
          icon: visitMarker,
          position: { lat: lat, lng: lng },
          map: map,
        });
        // Adding click event listener to show InfoWindow on marker click
        newMarker.addListener("click", () => {
          onMarkerClick(marker);
        });
        return newMarker;
      });
      // Cleanup the markers when the component is unmounted
      return () => {
        markers.forEach((marker) => {
          marker.setMap(null);
        });
      };
    }
  }, [map, location, markerData]);

  useEffect(() => {
    console.log("location tracking...");
    getCurrenttLocation();
    // watchUserLocation();
  }, [isLoaded, map]);

  useEffect(() => {
    if (map) {
      watchUserLocation();
    }
  }, [isLoaded, map]);

  //   useEffect(() => {
  //     getUserLocations();
  //   }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        // center={center}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
        {/* Create the markers */}

        {/* {data.length && (
          <Polyline
            path={data}
            options={{
              strokeColor: "#FF3D00",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        )} */}

        {/* {directions && <DirectionsRenderer directions={directions} />} */}
        {directions.map((direction, index) => (
          <DirectionsRenderer
            key={index}
            directions={direction}
            options={{
              suppressMarkers: true, // Hide waypoint markers
            }}
          />
        ))}

        {/* InfoWindow */}
        {/* {markerData.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
            onClick={() => onMarkerClick(marker)}
          />
        ))} */}

        {/* Render the InfoWindow */}
        {selectedMarker && (
          <InfoWindow
            anchor={{
              lat: Number(selectedMarker.lat),
              lng: Number(selectedMarker.lng),
            }}
            onCloseClick={() => setSelectedMarker(null)}
            zIndex={10}
          >
            <div className="bg-white px-4 py-2 rounded-md">
              <h3>{selectedMarker.name}</h3>
              <p>{selectedMarker.description}</p>
            </div>
          </InfoWindow>
        )}
        {/* Render the InfoWindow */}
      </GoogleMap>
      {selectedMarker ? (
        <div className="absolute  top-20 left-2 z-[2] flex items-end  overflow-auto">
          <section className="relative bg-white px-4 py-2 rounded-md shadow-md w-[200px] overflow-auto ">
            <div>
              <h3>Visit No: {selectedMarker?.visitno}</h3>
              <p className="font-semibold">{selectedMarker?.customername}</p>
              <p className="text-sm ">Address: {selectedMarker?.address}</p>
            </div>

            <div
              className="absolute  z-20 top-2 right-2"
              onClick={() => setSelectedMarker(null)}
            >
              <img src={crossIcon} alt="cross" className=" w-5" />
            </div>
          </section>
        </div>
      ) : (
        ""
      )}

      <section className="absolute bottom-24 right-4">
        <img
          src={currentLocationIcon}
          alt="location"
          onClick={() => map.panTo({ lat: location.lat, lng: location.lng })}
          className="w-10"
        />
        {/* <button
          onClick={() => map.panTo({ lat: location.lat, lng: location.lng })}
          className="px-3 py-1 bg-green-500 text-white"
        >
          CL
        </button> */}
      </section>
    </>
  );
};

export default GoogleMapRoutes;
