import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import config from "../Utils/config";
import Input from "./Input";
import "./form.css";
import { useNavigate } from "react-router-dom";
import ErrorPopup from "../popupModal/ErrorPopup";

// let statesDemo = [
//   "Please Select Your State",
//   "Delhi",
//   "Up",
//   "MP",
//   "Gujrat",
//   "Bangaluru",
//   "HEllo",
//   "Kolkata",
//   "ETC.",
//   "a",
//   "b",
//   "c",
//   "d",
//   "e",
// ];
// let cityDemo = [
//   "Select Your City",
//   "a",
//   "b",
//   "c",
//   "d",
//   "e",
//   "f",
//   "g",
//   "h",
//   "i",
// ];
function Form() {
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [message, setMessage] = useState("");
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    state: "",
    city: "",
    address: "",
    designation: "",
  });
  console.log(userDetails.state);
  const [states, setStates] = useState([]);
  //   const [isStateListOpen, setIsStateListOpen] = useState(false);

  const [holdState, setHoldState] = useState("");
  const [cities, setCities] = useState(null);

  useEffect(() => {
    getStates();
    // getUserDetails();
  }, []);

  useEffect(() => {
    getCities();
    // eslint-disable-next-line
  }, [holdState]);

  const getStates = async () => {
    const { data } = await axios.get(config.ipAddress + "/states");
    console.log(data.data);
    let allData = data.data;
    allData.sort((a, b) => {
      if (a.state.toLowerCase() < b.state.toLowerCase()) {
        return -1;
      } else if (a.state.toLowerCase() > b.state.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    setStates(allData);
  };

  const getCities = async () => {
    if (!holdState) return;
    const { data } = await axios.get(config.ipAddress + `/cities/${holdState}`);
    console.log(data.data);

    let allData = data.data;
    allData.sort((a, b) => {
      if (a.city.toLowerCase() < b.city.toLowerCase()) {
        return -1;
      } else if (a.city.toLowerCase() > b.city.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });

    setCities(allData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userDetails.name) {
      // return alert("Please enter your name!");
      setMessage("Please enter your name!");
      setShowErrorModal(true);
      return;
    }
    if (!userDetails.email) {
      // return alert("Please enter your email!");
      setMessage("Please enter your email!");
      setShowErrorModal(true);
      return;
    }
    if (!userDetails.state) {
      // return alert("Please enter your state!");
      setMessage("Please enter your state!");
      setShowErrorModal(true);
      return;
    }
    if (!userDetails.city) {
      // return alert("Please enter your city!");
      setMessage("Please enter your city!");
      setShowErrorModal(true);
      return;
    }
    // if (!userDetails.address) {
    //   return alert("Please enter your address!");
    // }

    if (!userDetails.designation) {
      // return alert("Please enter your designation!");
      setMessage("Please enter your designation!");
      setShowErrorModal(true);
      return;
    }
    function validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    let isCorrectEmail = validateEmail(userDetails.email);
    if (!isCorrectEmail) {
      // return alert("Please enter a valid email!");
      setMessage("Please enter valid email!");
      setShowErrorModal(true);
      return;
    }

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let userData = {
      fullname: userDetails.name,
      email: userDetails.email,
      mobile: phoneNum,
      state: userDetails.state,
      city: userDetails.city,
      address: userDetails.address,
      parmanentaddress: userDetails.address,
      designation: userDetails.designation,
    };

    let response = await axios.post(
      config.ipAddress + "/createprofile",
      userData
    );
    console.log(response.data.data.status);

    if (response.data.data.status === 1) {
      // navigate("/pending");

      let { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);
      console.log(data.data.name);
      localStorage.setItem("superonUserName", data?.data?.name);
      if (data.data.status === "0") {
        navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    }
  };

  const handleUser = async (phoneNum) => {
    console.log(phoneNum);

    if (!phoneNum) return;
    if (phoneNum) {
      const { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);

      console.log(data);
      // localStorage.setItem("superonUserName", data?.data?.name);

      if (data.data.status === "0") {
        // navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      navigate("/phone");
    }
  };

  useEffect(() => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    handleUser(phoneNum);
  }, []);

  // Name Input field 🚀🚀🚀
  const [placeholderName, setPlaceHolderName] = useState("Enter Full Name");
  const inputSpanRefName = useRef();
  const inputRefName = useRef();
  const handleName = () => {
    setPlaceHolderName("");
    inputSpanRefName.current.style.display = "block";
  };

  //   Email Input field 🚀🚀🚀
  const [placeholderEmail, setPlaceHolderEmail] = useState("Email");
  const inputSpanRefEmail = useRef();
  const inputRefEmail = useRef();
  const handleEmail = () => {
    setPlaceHolderEmail("");
    inputSpanRefEmail.current.style.display = "block";
  };

  //   Mobile Input Handle 🚀🚀🚀
  const [placeholderMobile, setPlaceHolderMobile] = useState("Mobile");
  const inputSpanRefMobile = useRef();
  const inputRefMobile = useRef();
  const handleMobile = () => {
    setPlaceHolderMobile("");
    inputSpanRefMobile.current.style.display = "block";
  };

  //   State Input field 🚀🚀🚀
  //   const [placeholderState, setPlaceHolderState] = useState("State");
  //   const inputSpanRefState = useRef();
  //   const inputRefState = useRef();
  //   const handleState = () => {
  //     setPlaceHolderState("");
  //     inputSpanRefState.current.style.display = "block";
  //     setIsStateListOpen(true);
  //     setStates(statesDemo);
  //   };

  //   const handleBlur = () => {
  //     setIsStateListOpen(false);
  //   };

  //   City Input field 🚀🚀🚀
  //   const [placeholderCity, setPlaceHolderCity] = useState("City");
  //   const inputSpanRefCity = useRef();
  //   const inputRefCity = useRef();
  //   const handleCity = () => {
  //     setPlaceHolderCity("");
  //     inputSpanRefCity.current.style.display = "block";
  //   };

  //   Address input field 🚀🚀🚀
  const [placeholderAddress, setPlaceHolderAddress] = useState("Address");
  const inputSpanRefAddress = useRef();
  const inputRefAddress = useRef();
  const handleAddress = () => {
    setPlaceHolderAddress("");
    inputSpanRefAddress.current.style.display = "block";
  };

  //   Designation input field 🚀🚀🚀
  const [placeholderDesignation, setPlaceHolderDesignation] =
    useState("Designation");
  const inputSpanRefDesignation = useRef();
  const inputRefDesignation = useRef();
  const handleDesignation = () => {
    setPlaceHolderDesignation("");
    inputSpanRefDesignation.current.style.display = "block";
  };

  const getUserDetails = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    console.log(phoneNum);

    if (phoneNum) {
      console.log("running...");
      let { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);
      console.log(data);
      if (data.data.status === "0") {
        navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      alert("Phone Num Not Found.");
    }
  };

  return (
    <div>
      <div className=" bg-[#fafafa]  px-4 py-3   ">
        {/* <h2 className="px-6  text-xl font-semibold pt-3">Create Your Profile</h2> */}
        <main className=" bg-white shadow-6xl rounded-[10px] border-[1px] border-gray-300">
          <div className="flex gap-2 px-2 pt-5 ">
            <div className="bg-blue-light w-[2.5px] rounded-full "></div>
            <div className="">
              <h1 className="text-lg font-semibold">Create Your Profile</h1>
            </div>
          </div>
          <div className="px-2 pt-5 pb-[30px] ">
            <form action="" className="flex flex-col gap-6">
              <Input
                lable={"Enter Full Name"}
                handleLable={handleName}
                placeholder={placeholderName}
                inputRef={inputRefName}
                inputSpanRef={inputSpanRefName}
                value={userDetails.name}
                changeValue={setUserDetails}
                userDetails={userDetails}
                name="name"
              />

              <Input
                lable={"Email"}
                handleLable={handleEmail}
                placeholder={placeholderEmail}
                inputRef={inputRefEmail}
                inputSpanRef={inputSpanRefEmail}
                value={userDetails.email}
                changeValue={setUserDetails}
                userDetails={userDetails}
                name="email"
                // pattern="/^[^\s@]+@[^\s@]+\.[^\s@]+$/"
              />

              {/* <Input
        lable={"Mobile"}
        handleLable={handleMobile}
        placeholder={placeholderMobile}
        inputRef={inputRefMobile}
        inputSpanRef={inputSpanRefMobile}
        value={userDetails.mobile}
        changeValue={setUserDetails}
        userDetails={userDetails}
        name="mobile"
      /> */}

              {/* <Input
        lable={"State"}
        handleLable={handleState}
        placeholder={placeholderState}
        inputRef={inputRefState}
        inputSpanRef={inputSpanRefState}
        value={userDetails.state}
        changeValue={setUserDetails}
        userDetails={userDetails}
        name="state"
        stateList={states}
        showStateList={isStateListOpen}
        setIsStateListOpen={setIsStateListOpen}
        handleBlur={handleBlur}
      /> */}

              <div className="">
                <select
                  size={1}
                  value={userDetails.state}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, state: e.target.value });
                    setHoldState(e.target.value);
                  }}
                  className={
                    userDetails.state === ""
                      ? "flex w-full rounded-md border-solid border-2 box-border h-14  border-gray-300  py-2 px-2 text-sm font-medium overflow-auto text-gray-400  focus:outline-none focus:border-blue-light bg-white"
                      : "flex w-full rounded-md border-solid border-2 box-border h-14  border-gray-300  py-2 px-2 text-sm font-medium overflow-auto  focus:outline-none focus:border-blue-light bg-white"
                  }
                >
                  <option value=" Select Your State" className="">
                    Select Your State
                  </option>
                  {states &&
                    states.sort().map((state, index) => {
                      return (
                        <option key={index} value={state.state}>
                          {state.state}
                        </option>
                      );
                    })}
                </select>
              </div>

              {/* <Input
        lable={"City"}
        handleLable={handleCity}
        placeholder={placeholderCity}
        inputRef={inputRefCity}
        inputSpanRef={inputSpanRefCity}
        value={userDetails.city}
        changeValue={setUserDetails}
        userDetails={userDetails}
        name="city"
      /> */}

              <div className="">
                <select
                  size={1}
                  value={userDetails.city}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, city: e.target.value })
                  }
                  className={
                    userDetails.city === ""
                      ? "flex w-full rounded-md border-solid border-2 box-border h-14 border-gray-300  py-2 px-2 text-sm font-medium overflow-auto text-gray-400  focus:outline-none focus:border-blue-light bg-white"
                      : "flex w-full rounded-md border-solid border-2 box-border h-14  border-gray-300  py-2 px-2 text-sm font-medium overflow-auto  focus:outline-none focus:border-blue-light bg-white"
                  }
                >
                  <option value="Select Your City">Select Your City</option>
                  {cities &&
                    cities.sort().map((item, index) => {
                      return (
                        <option key={index} value={item.city}>
                          {item.city}
                        </option>
                      );
                    })}
                </select>
              </div>

              {/* <Input
            lable={"Address"}
            handleLable={handleAddress}
            placeholder={placeholderAddress}
            inputRef={inputRefAddress}
            inputSpanRef={inputSpanRefAddress}
            value={userDetails.address}
            changeValue={setUserDetails}
            userDetails={userDetails}
            name="address"
          /> */}

              <Input
                lable={"Designation"}
                handleLable={handleDesignation}
                placeholder={placeholderDesignation}
                inputRef={inputRefDesignation}
                inputSpanRef={inputSpanRefDesignation}
                value={userDetails.designation}
                changeValue={setUserDetails}
                userDetails={userDetails}
                name="designation"
              />

              <button
                onClick={(e) => handleSubmit(e)}
                className=" rounded-md w-full bg-blue-light h-[53px] text-center text-xl font-semibold leading-7 text-white "
              >
                Next
              </button>
            </form>
          </div>
        </main>
      </div>
      {showErrorModal ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {showErrorModal ? (
        <div
          onClick={() => setShowErrorModal(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Form;
