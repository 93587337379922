import React, { useState, useEffect, useRef, useCallback } from "react";
// import {
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import loadingGif from "../../assets/loading2.gif";

import { Scrollbar } from "swiper";
import upArrow from "../../assets/up-arrow.png";
// import Datepicker from "flowbite-datepicker/Datepicker";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import "./target.css";
import axios from "axios";
import config from "../../Components/Utils/config";

let months = [
  { name: "January", target: 6456.5 },
  { name: "February", target: 27282 },
  { name: "March", target: 27282 },
  { name: "April", target: 27282 },
  { name: "May", target: 27282 },
  { name: "June", target: 27282 },
  { name: "July", target: 27282 },
  { name: "August", target: 27282 },
];

function Target() {
  const navigate = useNavigate();

  const phoneNum = localStorage.getItem("superonUserPhNum");

  const [category, setCategory] = useState([]);
  const [quarterData, setQuarterData] = useState([]);
  const [swiperArray, setSwiperArray] = useState([]);
  const [quarter, setQuarter] = useState("1");
  const [holdQuarterValue, setHoldQuarterValue] = useState("0");
  const [showLoading, setShowLoading] = useState(false);

  const [currentYearQuarter, setCurrentYearQuarter] = useState(
    new Date().getFullYear()
  );
  const [daysLeftInQuarter, setDaysLeftInQuarter] = useState(0);
  const [selectSlide, setSelectSlide] = useState(quarter);
  const [lastFourMonths, setLastFourMonths] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  // const [oldMonth, setOldMonth] = useState("");
  const [daysLeftInMonth, setDaysLeftInMonth] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [active, setActive] = useState({
    one: false,
    two: false,
    three: false,
    four: true,
  });

  // slab
  const [slabValues, setSlabValues] = useState([]);
  // category
  const [stanvacCategory, setStanvacCategory] = useState([]);

  // target
  // new targe tData variable
  const [totalTarget, setTotalTarget] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [balance, setBalance] = useState(0);

  // const [aerosolTarget, setAerosolTarget] = useState(0);
  // const [aerosolSale, setAerosolSale] = useState(0);
  // const [aerosolBalance, setAerosolBalance] = useState(0);
  // const [aerosolPercentage, setAerosolPercentage] = useState(0);

  const [aerosolData, setAeroSolData] = useState({
    target: 0,
    sale: 0,
    balance: 0,
    percentage: 0,
  });

  const [superonData, setSuperonData] = useState({
    target: 0,
    sale: 0,
    balance: 0,
    percentage: 0,
  });

  const [categoryValues, setCategoryValues] = useState({
    SSCWA: "00",
    MCAOP: "00",
    SSMCA: "00",
    MSP: "00",
    WAUX: "00",
  });
  const [slabStatus, setSlabStatus] = useState({
    SSCWA: "None",
    MCAOP: "None",
    SSMCA: "None",
    MSP: "None",
    WAUX: "None",
  });
  const [slabTargetValues, setSlabTargetValues] = useState({
    SSCWA: "00",
    MCAOP: "00",
    SSMCA: "00",
    MSP: "00",
    WAUX: "00",
  });

  function getCurrentMonth() {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const year = currentDate.getFullYear();
    const nextMonth = monthIndex + 1;
    const firstDayOfNextMonth = new Date(year, nextMonth, 1);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysLeftInMonth = Math.floor(
      (firstDayOfNextMonth - currentDate) / millisecondsPerDay
    );
    return {
      monthName: monthNames[monthIndex],
      monthIndex: monthIndex,
      currentYear: year,
      daysLeftInMonth,
    };
  }

  const [userTargetData, setUserTargetData] = useState({});
  const [percentage, setPercentage] = useState(0);

  // handle User
  const handleUser = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    if (phoneNum) {
      const { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);

      localStorage.setItem("superonUserName", data?.data?.name);

      if (data.data.status === "0") {
        navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      navigate("/phone");
    }
  };

  // getCurrent Year and Month index
  function getCurrentYearAndMonthIndex() {
    const date = new Date();
    const year = date.getFullYear();
    const monthIndex = date.getMonth();

    return { year, monthIndex };
  }

  const handleCategoryType = (value, type) => {
    if (type === 0) {
      return value;
    } else {
      return `₹ ${value} L`;
    }
  };

  // get Total target Sale Balance🚀🚀🚀
  const getTotalData = async (monthAndYear) => {
    let monthNameAndYear;
    if (monthAndYear) {
      monthNameAndYear = monthAndYear.split(",");
      // console.log(monthNameAndYear);

      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString("en-US", {
        month: "long",
      });

      // Check if the given month is in the past
      const variable =
        getFullMonthNameAndIndex(
          monthNameAndYear[0]
        )?.fullMonthName.toLowerCase() === currentMonth.toLowerCase()
          ? 1
          : 0;
      if (variable === 0) {
        setDaysLeftInMonth(0);
      } else {
        setDaysLeftInMonth(getCurrentMonth().daysLeftInMonth);
      }

      setCurrentMonth(
        getFullMonthNameAndIndex(monthNameAndYear[0]).fullMonthName
      );
      setCurrentYear(monthNameAndYear[1]);
    }

    setShowLoading(true);

    let data = {};
    if (monthAndYear) {
      data = {
        spid: phoneNum,
        month: getFullMonthNameAndIndex(monthNameAndYear[0])?.monthIndex + 1,
        year: monthNameAndYear[1].trim(),
      };
    } else {
      data = {
        spid: phoneNum,
        month: new Date().getMonth() + 1,
        // month: "5",
        year: currentYear,
      };
    }
    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdirecttarget`,
        data
      );

      console.log(response?.data?.data);
      console.log(response?.data?.data?.target[0]?.stanvacsale);

      let allData = response?.data?.data?.target[0];

      let totalTargetData = allData?.target;
      if (totalTargetData) {
        setTotalTarget(
          totalTargetData.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      } else {
        setTotalTarget(0);
      }

      let totalSale = allData?.stanvacsale;

      if (totalSale) {
        const parsedNumber = parseFloat(totalSale);
        const formattedNumber = parsedNumber.toFixed(2); //
        setTotalSale(formattedNumber);
      } else {
        setTotalSale(0);
      }

      let balance = Number(totalTargetData) - Number(totalSale);

      if (balance) {
        setBalance(
          balance.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
        if (balance < 1) {
          setBalance(0);
        }
      } else {
        setBalance(0);
      }

      let percent = (Number(totalSale) / Number(totalTargetData)) * 100;
      console.log(percent);

      if (percent) {
        setPercentage(Math.floor(percent));
      } else {
        setPercentage(0);
      }

      // "id": 1,
      // "name": "Tarun Chooni",
      // "target": "268.57",
      // "stanvacsale": "112.2722628",
      // "salepercentage": "0.418037244666195",
      // "aerosoltarget": "2880",
      // "aerosolsale": "3581",
      // "salestanvacsuperon": "119.74017719999999",
      // "stanvacsuperonsalepercentage": "0.44584345682689797",
      // "superonsale": "7.4679144",
      // "mobile": "8637538774",
      // "month": "8",
      // "year": "2023"

      // aerosol
      let aerosolTarget = allData?.aerosoltarget;
      let aerosolSale = allData?.aerosolsale;

      let aerosolBalance = Number(aerosolTarget) - Number(aerosolSale);

      let aerosolPercentage =
        (Number(aerosolSale) / Number(aerosolTarget)) * 100;

      setAeroSolData({
        target: aerosolTarget,
        sale: aerosolSale,
        balance: aerosolBalance < 1 ? 0 : aerosolBalance,
        percentage: aerosolPercentage > 100 ? 100 : aerosolPercentage,
      });

      // superon

      let superonTarget = allData?.target;
      let superonSale = allData?.salestanvacsuperon;
      if (!superonSale) {
        superonSale = 0;
      }

      let superonBalance = Number(superonTarget) - Number(superonSale);
      console.log(superonBalance, superonTarget, superonSale);
      if (!superonBalance) {
        superonBalance = 0;
      }
      let superonPercentage =
        (Number(superonSale) / Number(superonTarget)) * 100;

      if (!superonPercentage) {
        superonPercentage = 0;
      }
      // const parsedNumberSuperonSale = parseFloat(superonSale);
      // const formattedNumber = parsedNumber.toFixed(2); //

      setSuperonData({
        target: superonTarget,
        sale: parseFloat(superonSale).toFixed(2),
        balance:
          parseFloat(superonBalance).toFixed(2) < 1
            ? 0
            : parseFloat(superonBalance).toFixed(2),
        percentage:
          parseFloat(superonPercentage).toFixed(0) > 100
            ? 100
            : parseFloat(superonPercentage).toFixed(0),
      });

      let slabValues = response?.data?.data?.slab;

      if (percent < 75) {
        setSuperonData({
          target: 0,
          sale: 0,
          balance: 0,
          percentage: 0,
        });
      }
      setSlabValues(slabValues);
      setShowLoading(false);

      return;

      let category = response?.data?.data?.category;

      const filteredCategory1 = category.filter(
        (category) => category.categoryname === "All"
      );
      const filteredCategory2 = category.filter(
        (category) => category.categoryname !== "All"
      );
      const filteredCategory3 = category.filter(
        (category) => category.categoryname === "Superon"
      );
      console.log(filteredCategory1);
      let totatSaleData = filteredCategory1[0]?.totalachievement;
      if (totatSaleData) {
        setTotalSale(
          totatSaleData.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      } else {
        setTotalSale(0);
      }
      let balanceData =
        response?.data?.data?.target[0]?.target -
        filteredCategory1[0]?.totalachievement;
      if (balanceData) {
        setBalance(
          balanceData.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      } else {
        setBalance(0);
      }

      // aerosol
      // let aerosolBalance =
      //   Math.round(Number(filteredCategory2[0]?.totaltarget)) -
      //   filteredCategory2[0]?.totalachievement;

      // let aerosolPercentage =
      //   (filteredCategory2[0]?.totalachievement /
      //     Math.round(Number(filteredCategory2[0]?.totaltarget))) *
      //   100;

      setAeroSolData({
        target: Math.round(Number(filteredCategory2[0]?.totaltarget)),
        sale: filteredCategory2[0]?.totalachievement,
        balance: aerosolBalance,
        percentage: aerosolPercentage,
      });

      // console.log(filteredCategory3);

      // superon
      if (filteredCategory3.length) {
        console.log(totatSaleData);
        let superonSale = (
          filteredCategory3[0]?.totalachievement + totatSaleData
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        console.log(superonSale);
        let superonBalance = (totalTargetData - superonSale).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        let superonPercentage = Math.floor(
          (superonSale / totalTargetData) * 100
        );
        setSuperonData({
          sale: superonSale,
          balance: superonBalance,
          percentage: superonPercentage,
        });
      } else {
        let superonBalance = Number(
          (totalTargetData - totatSaleData).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
        let superonPercentage = Math.floor(
          (totatSaleData / totalTargetData) * 100
        );

        console.log(superonBalance, superonPercentage);
        setSuperonData({
          sale: totatSaleData,
          balance: superonBalance ? superonBalance : 0,
          percentage: superonPercentage ? superonPercentage : 0,
        });
      }

      setStanvacCategory(filteredCategory2);

      let percentage =
        (filteredCategory1[0]?.totalachievement /
          response?.data?.data?.target[0]?.target) *
        100;
      if (percentage) {
        setPercentage(Math.floor(percentage));
      } else {
        setPercentage(0);
      }

      setSlabValues(response?.data?.data?.slab);
      setShowLoading(false);
      console.log(aerosolData);
    } catch (error) {
      setShowLoading(false);
    }
  };

  // convert Number with Comma
  const numberWithCommas = (numberStr) => {
    if (!numberStr) return "00";

    let num = Number(numberStr);

    const formattedNumber = (num / 100000).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    console.log(formattedNumber); // Output: 2,422.70

    return formattedNumber;
  };
  const numberWithCommas2 = (numberStr) => {
    if (!numberStr) return "00";

    let num = Number(numberStr);

    const formattedNumber = (num / 100000).toLocaleString("en-US", {});
    console.log(formattedNumber); // Output: 2,422.70

    return formattedNumber;
  };

  // get Current Quarter 🚀🚀🚀
  function getCurrentQuarterAndDays() {
    // get the current month (0-11)
    const currentMonth = new Date().getMonth();

    // divide the 12 months into quarters
    const quarters = {
      1: [3, 4, 5], // Quarter 1: April, May, June
      2: [6, 7, 8], // Quarter 2: July, August, September
      3: [9, 10, 11], // Quarter 3: October, November, December
      4: [0, 1, 2], // Quarter 4: January, February, March
    };

    // get the current quarter based on the current month
    let currentQuarter;
    for (const quarter in quarters) {
      if (quarters[quarter].includes(currentMonth)) {
        currentQuarter = quarter;
        break;
      }
    }

    // get current month 🚀🚀🚀
    function getCurrentMonth() {
      const now = new Date();
      const month = now.getMonth();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[month];
    }

    // calculate the number of days left in the current quarter
    const currentQuarterMonths = quarters[currentQuarter];
    const currentQuarterEndMonth =
      currentQuarterMonths[currentQuarterMonths.length - 1];
    const currentQuarterEndDate = new Date(
      new Date().getFullYear(),
      currentQuarterEndMonth + 1,
      0
    );
    const daysLeftInQuarter = Math.ceil(
      (currentQuarterEndDate - new Date()) / (1000 * 60 * 60 * 24)
    );

    // console.log("Current Quarter:", typeof currentQuarter);
    // console.log("Days left in Current Quarter:", daysLeftInQuarter);
    setQuarter(currentQuarter);
    setHoldQuarterValue(currentQuarter);
    setDaysLeftInQuarter(daysLeftInQuarter);
    return { currentQuarter, daysLeftInQuarter };
  }

  // get last four months
  function getLastFourMonths() {
    var current_date = new Date();
    var last_four_months = [];

    for (var i = 0; i < 4; i++) {
      var month = (current_date.getMonth() - i + 12) % 12;
      var year =
        current_date.getFullYear() - (current_date.getMonth() < i ? 1 : 0);
      var month_name = new Date(year, month, 1).toLocaleString("default", {
        month: "short",
      });
      last_four_months.push(month_name + ", " + year);
    }
    console.log(last_four_months);
    setLastFourMonths(last_four_months);
    return last_four_months;
  }
  // const getCategory = async () => {
  //   let data = {};
  //   let response = await axios.post(config.ipAddress + `/categories`);
  //   console.log(response?.data?.data);
  //   setCategory(response?.data?.data);
  // };

  // getFullMonthName and Index 🚀🚀🚀
  function getFullMonthNameAndIndex(monthAbbreviation) {
    if (!monthAbbreviation) return null;
    const date = new Date(`2000-${monthAbbreviation}-01`);
    const fullMonthName = date.toLocaleString("en-US", { month: "long" });
    const monthIndex = date.getMonth();
    return {
      fullMonthName,
      monthIndex,
    };
  }

  useEffect(() => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    console.log(phoneNum);
    if (!phoneNum) {
      navigate("/phone");
    }
  }, []);

  useEffect(() => {
    handleUser();

    getTotalData();

    getCurrentQuarterAndDays();
    getLastFourMonths();

    const { monthName, monthIndex, currentYear, daysLeftInMonth } =
      getCurrentMonth();
    setCurrentMonth(monthName);
    setDaysLeftInMonth(daysLeftInMonth);
  }, []);

  const swiperRef = useRef(null);
  // console.log(swiperRef.current.swiper.realIndex);

  return (
    <div>
      <div className="bg-[#fafafa] h-[90%]  w-full py-[17px] px-[13px] overflow-auto">
        <div className="bg-white  py-4 pr-4 rounded-[10px] shadow-6xl border-[1px] border-gray-300">
          <div className="flex gap-2">
            <div className="bg-blue-light w-[2.5px] ml-4 rounded-full"></div>
            <div className="-space-y-1 ">
              <h1 className="text-lg  ">Target</h1>
              <p>
                {currentMonth}, {currentYear}
              </p>
            </div>
          </div>

          <div className=" overflow-x-auto mt-4  ml-7">
            <table
              id="tableId1"
              className="w-full text-sm text-left text-gray-400 "
            >
              <thead className="text-xs   ">
                <tr className=" text-white">
                  <th
                    scope="col"
                    className="pb-2 w-[33.3%]   text-blue-light  text-sm"
                  >
                    TARGET
                  </th>

                  <th
                    scope="col"
                    className="pb-2 w-[33.3%]  text-center text-blue-light  text-sm"
                  >
                    SALE
                  </th>

                  <th
                    scope="col"
                    className="pb-2  w-[33.3%] text-right  text-blue-light  text-sm"
                  >
                    BALANCE
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="text-black">
                    <div className="-space-y-1">
                      <p className="text-sm">₹ {totalTarget || "00"} lacs</p>
                      <p className="text-[10px]">(for {currentMonth})</p>
                    </div>
                  </td>

                  <td className="text-black text-center">
                    <div className="-space-y-1">
                      <p className=" text-sm">₹ {totalSale || "00"} lacs</p>
                      <p className="text-[10px]">(Invoice)</p>
                    </div>
                  </td>

                  <td className="text-black text-right">
                    <div className="-space-y-1 text-right">
                      <p className=" text-sm">₹ {balance || "00"} lacs</p>
                      <p className="text-[10px] ">
                        ({daysLeftInMonth} days to achieve)
                      </p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <div class="w-full bg-[#E2E2E2] rounded-full mt-2 ">
                      <div
                        className={`bg-[#6CC823] py-[4px] text-center text-[10px] font-medium leading-none text-primary-100 rounded-full text-white`}
                        style={{
                          width: `${percentage ? percentage : "0"}%`,
                        }}
                      >
                        {percentage}%
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <table
                      id="tableId1"
                      className="w-full text-sm text-left text-gray-400 mt-4"
                    >
                      <thead className="text-xs   ">
                        <tr className=" text-white ">
                          <th
                            colSpan={3}
                            scope="col"
                            className=" text-blue-light  text-sm border-b border-gray-300 uppercase"
                          >
                            AEROSOL
                          </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        <tr className="py-3">
                          <td className="text-black">
                            <div className="-space-y-1 mt-3">
                              <p className=" text-sm">
                                {aerosolData?.target || "00"}
                              </p>
                              <p className="text-[10px]">Can</p>
                            </div>
                          </td>

                          <td className="text-black text-center">
                            <div className="-space-y-1 mt-3">
                              <p className=" text-sm">
                                {aerosolData.sale || "00"}
                              </p>
                              <p className="text-[10px]">Can</p>
                            </div>
                          </td>

                          <td className="text-black text-right">
                            <div className="-space-y-1 text-right mt-3">
                              <p className=" text-sm">
                                {aerosolData.balance || "00"}
                              </p>
                              <p className="text-[10px]">Can</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <div class="w-full bg-[#E2E2E2] rounded-full mt-2">
                      <div
                        className={`bg-[#6CC823] py-[4px] text-center text-[10px] font-medium leading-none text-primary-100 rounded-full text-white`}
                        style={{
                          width: `${
                            aerosolData.percentage
                              ? aerosolData.percentage > 100
                                ? 100
                                : aerosolData.percentage
                              : "0"
                          }%`,
                        }}
                      >
                        {Math.ceil(aerosolData?.percentage) || "0"}%
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}>
                    <table
                      id="tableId1"
                      className="w-full text-sm text-left text-gray-400 mt-4"
                    >
                      <thead className="text-xs   ">
                        <tr className=" text-white ">
                          <th
                            colSpan={3}
                            scope="col"
                            className=" text-blue-light  text-sm border-b border-gray-300"
                          >
                            WITH SUPERON
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-black">
                            <div className="-space-y-1 mt-3">
                              <p className="text-sm">
                                ₹ {superonData.target || "00"} lacs
                              </p>
                              {/* <p className="text-[10px]">(for {currentMonth})</p> */}
                            </div>
                          </td>

                          <td className="text-black text-center">
                            <div className="-space-y-1 mt-3">
                              <p className=" text-sm">
                                ₹ {superonData.sale || "00"} lacs
                              </p>
                              {/* <p className="text-[10px]">(Invoice)</p> */}
                            </div>
                          </td>

                          <td className="text-black text-right">
                            <div className="-space-y-1 text-right mt-3">
                              <p className=" text-sm">
                                ₹ {superonData.balance || "00"} lacs
                              </p>
                              {/* <p className="text-[10px] ">
                        ({daysLeftInMonth} days to achieve)
                      </p> */}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={3}>
                            <div class="w-full bg-[#E2E2E2] rounded-full mt-2">
                              <div
                                className={`bg-[#6CC823] py-[4px] text-center text-[10px] font-medium leading-none text-primary-100 rounded-full text-white`}
                                style={{
                                  width: `${
                                    superonData.percentage
                                      ? superonData.percentage
                                      : "0"
                                  }%`,
                                }}
                              >
                                {superonData.percentage}%
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Slider Container */}

        <section
          className={`bg-white px-4 ${
            slabValues.length === 0 ? "py-4" : "pt-4"
          } mt-4  shadow-md rounded-[10px] border-[1px] border-gray-300`}
        >
          <div className="flex gap-2  bg-[#fff] ">
            <div className="bg-blue-light w-[2.5px] rounded-full"></div>
            <div className="">
              <h1 className="text-base ">Incentive</h1>
            </div>
          </div>

          <Swiper
            // onSwiper={setSwiperRef}
            // initialSlide={1}
            // onRealIndexChange={2}
            slidesPerView={4}
            // centeredSlides={true}
            spaceBetween={4}
            navigation={true}
            scrollbar={{
              hide: true,
            }}
            modules={[Scrollbar]}
            className="mySwiper"
            ref={swiperRef}
          >
            {slabValues.length
              ? slabValues.map((item, index) => {
                  return (
                    <SwiperSlide
                      className="  bg-white  rounded-md text-center"
                      // onClick={() => getUserTargetDataByQuarter(quarterData[0]?.quarter)}
                      key={index}
                    >
                      <div className=" p-2">
                        <div className=" ">
                          {/* <div className="line bg-[#FF971A] w-[2px] mr-2 rounded-full"></div> */}

                          <h3 className="text-sm text-black text-center ">
                            {item.target}%
                          </h3>
                        </div>
                        <p className="text-[12px] mt-1 text-[#009788] text-center">
                          {/* ₹ {numberWithCommas2(slabTargetValues.SSCWA)} L */}
                          {item.salary}%
                        </p>
                      </div>
                    </SwiperSlide>
                  );
                })
              : ""}
          </Swiper>
        </section>

        {/* Slider */}
        <section className="mt-4 bg-white shadow-md rounded-md pr-4 border-[1px] border-gray-300">
          <main className="bg-white py-2 rounded-md pl-4 flex items-center gap-2">
            {/* for quarter 1 */}
            <div className="w-[2.4px] h-[25px]  bg-blue-light rounded-full"></div>

            <section className="flex justify-between items-center  gap-1 w-full">
              <div
                className={`flex   py-2 `}
                // onClick={() => getUserTargetDataByQuarter("2", currentYear - 1)}
                onClick={() => {
                  getTotalData(lastFourMonths[3]);
                  setActive({
                    one: true,
                    two: false,
                    three: false,
                    four: false,
                  });
                }}
              >
                <h3
                  className={`text-sm text-center text-black   ${
                    active.one ? "border-blue-light" : "border-white"
                  } border-b-[2px] `}
                >
                  {lastFourMonths[3]}
                </h3>

                {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
              </div>

              <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>
              <div
                className={`flex    py-2  `}
                // onClick={() => getUserTargetDataByQuarter("3", currentYear - 1)}
                onClick={() => {
                  getTotalData(lastFourMonths[2]);
                  setActive({
                    one: false,
                    two: true,
                    three: false,
                    four: false,
                  });
                }}
              >
                <h3
                  className={`text-sm text-center text-black  ${
                    active.two ? "border-blue-light" : "border-white"
                  } border-b-[2px] `}
                >
                  {lastFourMonths[2]}
                </h3>

                {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
              </div>
              <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

              <div
                className={`flex    py-2 `}
                // onClick={() => getUserTargetDataByQuarter("4", currentYear - 1)}
                onClick={() => {
                  getTotalData(lastFourMonths[1]);
                  setActive({
                    one: false,
                    two: false,
                    three: true,
                    four: false,
                  });
                }}
              >
                {/* <div className="w-[2.4px] bg-gray-300 mr-2"></div> */}

                <h3
                  className={`text-sm text-center text-black   ${
                    active.three ? "border-blue-light" : "border-white"
                  } border-b-[2px] `}
                >
                  {lastFourMonths[1]}
                </h3>

                {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
              </div>
              <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

              <div
                className={`flex    py-2  `}
                // onClick={() => getUserTargetDataByQuarter("1", currentYear)}
                onClick={() => {
                  getTotalData(lastFourMonths[0]);
                  setActive({
                    one: false,
                    two: false,
                    three: false,
                    four: true,
                  });
                }}
              >
                {/* <div className="w-[2px] bg-gray-300 mr-2"></div> */}

                <h3
                  className={`text-sm text-center text-black   ${
                    active.four ? "border-blue-light" : "border-white"
                  } border-b-[2px] `}
                >
                  {lastFourMonths[0]}
                </h3>

                {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
              </div>
            </section>
          </main>
        </section>
      </div>
      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default Target;
