import React from "react";
import sucessIcon from "../../assets/successTick.png";
import crossIcon from "../../assets/popupCross.png";
import crossIcon2 from "../../assets/cross.svg";

function ErrorPopup({ message, setShowErrorModal }) {
  return (
    <div>
      <div className="w-[300px] relative h-[100px] flex flex-col justify-center gap-2 items-center bg-[#fff] rounded-md  border-[1px] border-gray-300 shadow-6xl px-2">
        <img src={crossIcon2} alt="logo" className="w-6" />
        <p>{message}</p>

        <img
          src={crossIcon}
          alt="cross-logo"
          className="absolute right-2 top-2"
          onClick={() => setShowErrorModal(false)}
        />
      </div>
    </div>
  );
}

export default ErrorPopup;
