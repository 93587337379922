import React, { useContext, useEffect, useState } from "react";
import menuLogo from "../assets/Menu.png";
import stanvacLogo from "../assets/stanvaclogo.png";
import notificationLogo from "../assets/notification-icon.png";
// import callIcon from "../assets/call2.svg";
import callIcon from "../assets/call1.svg";

import backArrow from "../assets/back-arrow.png";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import HelpModal from "../Components/HelpModal";
import PunchPopup from "../Components/popupModal/PunchPopup";

export const modalContext = React.createContext();
function Navbar() {
  const navigate = useNavigate();

  const [showBackArrow, setShowBackArrow] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  let handlePunchCancelandSuccess = (status) => {
    if (status === false) return setIsModalOpen2(false);
    if (status === true) {
      localStorage.removeItem("superonUserPhNum");
      localStorage.removeItem("superonUserName");

      navigate("/phone");
      console.log("running...");
      setIsModalOpen2(false);
    }
  };

  const handleSideBar = () => {
    setIsSidebarOpen(false);
  };
  const handleModal = () => {
    setIsModalOpen(false);
  };
  const handleBackArrow = () => {
    setShowBackArrow(true);
    let href = window.location.href;
    let hostName = window.location.hostname;
    let port = window.location.port;
    let protocol = window.location.protocol;

    if (href === `${protocol}//${hostName}/dashboard`) {
      return setShowBackArrow(false);
    }

    if (href === `${protocol}//${hostName}/dashboard/target`) {
      return setShowBackArrow(false);
    }

    if (href === `${protocol}//${hostName}:${port}/dashboard`) {
      return setShowBackArrow(false);
    }

    if (href === `${protocol}//${hostName}:${port}/dashboard/target`) {
      return setShowBackArrow(false);
    }
  };

  useEffect(() => {
    handleBackArrow();
  });
  return (
    <div
      className={`bg-blue-light py-5 flex ${
        showBackArrow ? "pl-2 pr-5" : "px-5"
      } justify-between items-center overflow-hidden`}
    >
      <div className="flex items-center gap-2">
        {showBackArrow && (
          <img
            src={backArrow}
            alt="menu-logo"
            className="w-6 "
            onClick={() => navigate(-1)}
          />
        )}
        <img
          src={menuLogo}
          alt="menu-logo"
          className="w-6 h-4"
          onClick={() => setIsSidebarOpen(true)}
        />
      </div>

      <div>
        <img src={stanvacLogo} alt="stanvac-logo" className="w-[100px]" />
      </div>

      <div>
        <a href="tel:8527199811">
          <img
            src={callIcon}
            alt="help-logo"
            className="w-6  "
            // onClick={() => setIsModalOpen(true)}
          />
        </a>
      </div>
      {isSidebarOpen && (
        <>
          <div className="absolute top-0 left-0 z-20 w-fit h-[100dvh] overflow-hidden">
            <modalContext.Provider
              value={{
                setIsModalOpen2,
                handlePunchCancelandSuccess,
              }}
            >
              <Sidebar handleSideBar={handleSideBar} />
            </modalContext.Provider>
          </div>
          <div
            className="absolute top-0 left-0 w-screen z-[5] h-[100dvh] bg-black opacity-80 "
            onClick={() => handleSideBar()}
          ></div>
        </>
      )}

      {isModalOpen ? (
        <div className="absolute z-20  text-center  top-[50%] left-[50%] mt-[137px] mr-0 mb-0 ml-[-150px]">
          <HelpModal handleModal={handleModal} />
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-screen h-screen bg-slate-300 absolute top-0 left-0 opacity-30 z-[1]"
        ></div>
      ) : (
        ""
      )}

      {isModalOpen2 ? (
        <div
          className="absolute z-20 w-fit  text-center top-[300%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px] shadow-6xl"
          // onBlur={() => setIsModalOpen(false)}
        >
          <PunchPopup
            setIsModalOpen={setIsModalOpen}
            message={"Conform log out ?"}
            handlePunchCancelandSuccess={handlePunchCancelandSuccess}
            // handlePunchOutCancelandSuccess={handlePunchOutCancelandSuccess}
          />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {/* {isModalOpen2 ? (
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default Navbar;
