import React, { useEffect, useState } from "react";
import Navbar from "../../Dashboard/Navbar";
import "./privacy.css";
import axios from "axios";
import config from "../Utils/config";

function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const getPrivacyPolicy = async () => {
    let response = await axios.get(config.ipAddress + `/term`);
    console.log(response?.data?.data[0]?.title);

    setPrivacyPolicy(response?.data?.data);
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  // let htmlString = privacyPolicy[0]?.title;

  // console.log(typeof htmlString);
  return (
    <div className="h-[100dvh] overflow-auto">
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>

      {console.log(privacyPolicy)}
      <section
        className="bg-white  px-5  my-[27px] pb-6"
        // dangerouslySetInnerHTML={{ __html: "<p>Privacy Policy </P>" }}
        dangerouslySetInnerHTML={{ __html: privacyPolicy[0]?.title }}
      >
        {/* <h1
          className=""
          dangerouslySetInnerHTML={{ __html: privacyPolicy[0]?.title }}
        ></h1> */}

        {/* <hr className="h-[2px] bg-gray-400 mt-4" />

        <h1 className="mt-3 font-semibold">
          Last updated: December 15, 2020.
          <span className="opacity-60">To see prior version, click here</span>
        </h1> */}

        {/* <p className="mt-3 text-[#545454]">{privacyPolicy[0]?.disclaimer}</p> */}

        {/* <p className="mt-3 text-[#545454]">
          Vero eos et accusamus et iusto odio dignis simos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deser unt mollitia animi, id est
          laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
          disti nctio. Nam libero tempore, cum soluta nobis est eligendi optio
          cumque nihil impedit quo minus id quod maxime placeat facere possimus,
          omnis
        </p>

        <div className="mt-3 ">
          <ul className="px-3 text-[#2A6194]">
            <li className="mb-2">
              What et iusto odio dignissimos ducimus qui blanditiis praesentium
              voluptatum deleniti atque
            </li>
            <li className="mb-2">What et iusto odio dignissimos ducimus qui</li>
            <li className="mb-2">What et iusto odio dignissimos ducimus</li>
            <li className="mb-2">
              What et iusto odio dignissimos ducimus qui blanditiis praesentium
              voluptatum deleniti atque
            </li>
            <li className="mb-2">
              What et iusto odio dignissimos ducimus qui blanditiis
            </li>
            <li className="mb-2">
              What et iusto odio dignissimos ducimus qui blanditiis praesentium
              voluptatum deleniti atque
            </li>
            <li className="mb-2">What et iusto odio dignissimos ducimus</li>
          </ul>
        </div>

        <h1 className="mt-7 font-semibold">
          What Personal Information About Customers Does Superon Collect?
        </h1>

        <p className="mt-3 text-[#545454]">
          At vero eos et accusamus et iusto odio dignis siducimus qui blanditiis
          praesentium voluptatum deleniti atque corrupti quos dolores et quas
          molestias excepturi sint occaecati cupiditate non provident, similique
          sunt in culpa qui officia deser unt mollitia animi, id est laborum et
          dolorum fuga. Et harum quidem rerum facilis est et expedita disti
          nctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque
          nihil impedit.
        </p>

        <p className="mt-3 text-[#545454]">
          Vero eos et accusamus et iusto odio dignis simos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deser unt mollitia animi, id est
          laborum et dolorum fuga. Et harum quidem rerum facilis
        </p>

        <h1 className="mt-7 font-semibold">
          For What Purposes Does Superon Use Your Personal Information?
        </h1>
        <p className="mt-3 text-[#545454]">
          Vero eos et accusamus et iusto odio dignis simos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deser unt mollitia animi, id est
          laborum et dolorum fuga. Et harum quidem rerum facilis
        </p>

        <div className="mt-4">
          <ul className="px-3">
            <li className="font-semibold mb-2">
              What et iusto odio dignissimos ducimus
            </li>
            <p className="text-[#545454]">
              Eos et accusamus et iusto odio dignis simos ducimus qui blanditiis
              praesentium voluptatum deleniti atque corrupti quos dolores et
              quas molestias excepturi sint occaecati
            </p>
            <li className="font-semibold mt-2 mb-2">
              What et iusto odio dignissimos ducimus
            </li>
            <p className="text-[#545454]">
              Eos et accusamus et iusto odio dignis simos ducimus qui blanditiis
              praesentium voluptatum deleniti atque corrupti quos dolores et
              quas molestias excepturi sint occaecati
            </p>
          </ul>
        </div>

        <div className="mt-9 py-8 px-4  border-2 border-gray-300 rounded-md shadow-5xl">
          <h4 className="text-[#55595A]">Was this information helpful ?</h4>

          <div className="mt-6 space-x-4">
            <button className="border-[1px] border-gray-400 px-10 py-2 rounded-md">
              Yes
            </button>

            <button className="border-[1px] border-gray-400 px-10 py-2 rounded-md">
              No
            </button>
          </div>
        </div> */}
      </section>
    </div>
  );
}

export default PrivacyPolicy;
