import React, { useEffect, useRef, useState } from "react";
import useCurrentLocations from "../customHook/useCurrentLocations";
import visitMarker from "../../assets/blueMarker.svg";
import markerIcon from "../../assets/locationIconAnimated.gif";
import currentLocationIcon from "../../assets/currentLocationIcon.svg";

import crossIcon from "../../assets/cross.svg";

const stations = [
  { lat: 28.6315, lng: 77.2167, name: "Koushik" }, // Connaught Place
  { lat: 28.6129, lng: 77.2295, name: "Koushik" }, // India Gate
  { lat: 28.6562, lng: 77.241, name: "Koushik" }, // Red Fort (Lal Qila)
  { lat: 28.5535, lng: 77.2588, name: "Koushik" }, // Lotus Temple
  { lat: 28.5245, lng: 77.1855, name: "Koushik" }, // Qutub Minar
  { lat: 28.6127, lng: 77.2773, name: "Koushik" }, // Akshardham Temple
  { lat: 28.5933, lng: 77.2507, name: "Koushik" }, // Humayun's Tomb
  { lat: 28.6507, lng: 77.2334, name: "Koushik" }, // Jama Masjid
  { lat: 28.6072, lng: 77.2195, name: "Koushik" }, // National Museum
  { lat: 28.6406, lng: 77.2495, name: "Koushik" }, // Raj Ghat
  { lat: 28.6579, lng: 77.2305, name: "Koushik" }, // Chandni Chowk
  { lat: 28.6266, lng: 77.2091, name: "Koushik" }, // Gurudwara Bangla Sahib
  { lat: 28.6033, lng: 77.2464, name: "Koushik" }, // National Zoological Park
  { lat: 28.6098, lng: 77.2433, name: "Koushik" }, // Purana Qila (Old Fort)
  { lat: 28.5709, lng: 77.2066, name: "Koushik" }, // Dilli Haat
  { lat: 28.5931, lng: 77.2188, name: "Koushik" }, // Lodi Gardens
  { lat: 28.6271, lng: 77.2166 }, // Jantar Mantar
  { lat: 28.4984, lng: 77.086 }, // DLF CyberHub
  { lat: 28.5891, lng: 77.2234 }, // India Habitat Centre
  { lat: 28.5859, lng: 77.1783 }, // National Rail Museum
  { lat: 28.4675, lng: 77.0669 }, // Kingdom of Dreams
  { lat: 28.5285, lng: 77.2182 }, // Select Citywalk
  { lat: 28.6013, lng: 77.1899 }, // Nehru Planetarium
  { lat: 28.6105, lng: 77.227 }, // Shankar's International Dolls Museum
  { lat: 28.7074, lng: 77.1097 }, // Adventure Island
];

function MapWithDirections() {
  const [data, visitLocations, markerData] = useCurrentLocations();
  console.log(data, visitLocations, markerData);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [googleMap, setGooglemap] = useState(null);
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const directionsRendererRef = useRef(null);
  let map;

  const onMarkerClick = (marker) => {
    // console.log("clicked", marker);
    setSelectedMarker(marker);
  };

  function watchUserLocation() {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000,
    };

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        setLocation({
          lat: latitude,
          lng: longitude,
        });

        console.log(latitude, longitude);

        // Pan the map to the new location
        // map.panTo({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error watching user's location: ", error);
      },
      options
    );

    // Return the watchId so that it can be used to clear the watch later if needed.
    return watchId;
  }

  useEffect(() => {
    watchUserLocation();
    if (window.google) {
      map = new window.google.maps.Map(document.getElementById("map"), {
        disableDefaultUI: true, // Disable default UI controls
      });
      setGooglemap(map);
      // Zoom and center map automatically by stations (each station will be in visible map area)
      const lngs = data.map((station) => station.lng);
      const lats = data.map((station) => station.lat);
      map.fitBounds({
        west: Math.min(...lngs),
        east: Math.max(...lngs),
        north: Math.min(...lats),
        south: Math.max(...lats),
      });

      const markers = markerData.map((marker) => {
        let lat = Number(marker.lat);
        let lng = Number(marker.lng);
        const newMarker = new window.google.maps.Marker({
          icon: visitMarker,
          position: { lat: lat, lng: lng },
          map: map,
        });
        // Adding click event listener to show InfoWindow on marker click
        newMarker.addListener("click", () => {
          onMarkerClick(marker);
        });
        return newMarker;
      });

      // Show stations on the map as markers
      // for (let i = 0; i < stations.length; i++) {
      //   const newMarker = new window.google.maps.Marker({
      //     position: stations[i],
      //     map: map,
      //     title: stations[i].name,
      //   });

      //   newMarker.addListener("click", () => {
      //     alert("OK");
      //     onMarkerClick(marker);
      //   });
      // }
      // return () => {
      //   newMarker.forEach((marker) => {
      //     marker.setMap(null);
      //   });
      // };

      // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      const parts = [];
      const max = 25 - 1;
      for (let i = 0; i < data.length; i = i + max) {
        parts.push(data.slice(i, i + max + 1));
      }

      // Service callback to process service results
      const service_callback = function (response, status) {
        if (status !== "OK") {
          console.log("Directions request failed due to " + status);
          return;
        }
        const renderer = new window.google.maps.DirectionsRenderer();
        renderer.setMap(map);
        renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
        renderer.setDirections(response);
      };

      // Send requests to service to get route (for stations count <= 25 only one request will be sent)
      for (let i = 0; i < parts.length; i++) {
        // Waypoints does not include first station (origin) and last station (destination)
        const waypoints = [];
        for (let j = 1; j < parts[i].length - 1; j++)
          waypoints.push({ location: parts[i][j], stopover: false });
        // Service options
        const service_options = {
          origin: parts[i][0],
          destination: parts[i][parts[i].length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        const service = new window.google.maps.DirectionsService();
        service.route(service_options, service_callback);
      }
    }
  }, [markerData]);

  // Visit Routes
  useEffect(() => {
    const stations = [
      { lat: 28.6315, lng: 77.2167 }, // Connaught Place
      { lat: 28.6129, lng: 77.2295 }, // India Gate
      { lat: 28.6562, lng: 77.241 }, // Red Fort (Lal Qila)
      { lat: 28.5535, lng: 77.2588 }, // Lotus Temple
      { lat: 28.5245, lng: 77.1855 }, // Qutub Minar
      { lat: 28.6127, lng: 77.2773 }, // Akshardham Temple
      { lat: 28.5933, lng: 77.2507 }, // Humayun's Tomb
      { lat: 28.6507, lng: 77.2334 }, // Jama Masjid
      { lat: 28.6072, lng: 77.2195 }, // National Museum
      { lat: 28.6406, lng: 77.2495 }, // Raj Ghat
      { lat: 28.6579, lng: 77.2305 }, // Chandni Chowk
      { lat: 28.6266, lng: 77.2091 }, // Gurudwara Bangla Sahib
      { lat: 28.6033, lng: 77.2464 }, // National Zoological Park
      { lat: 28.6098, lng: 77.2433 }, // Purana Qila (Old Fort)
      { lat: 28.5709, lng: 77.2066 }, // Dilli Haat
      { lat: 28.5931, lng: 77.2188 }, // Lodi Gardens
      { lat: 28.6271, lng: 77.2166 }, // Jantar Mantar
      { lat: 28.4984, lng: 77.086 }, // DLF CyberHub
      { lat: 28.5891, lng: 77.2234 }, // India Habitat Centre
      { lat: 28.5859, lng: 77.1783 }, // National Rail Museum
      { lat: 28.4675, lng: 77.0669 }, // Kingdom of Dreams
      { lat: 28.5285, lng: 77.2182 }, // Select Citywalk
      { lat: 28.6013, lng: 77.1899 }, // Nehru Planetarium
      { lat: 28.6105, lng: 77.227 }, // Shankar's International Dolls Museum
      { lat: 28.7074, lng: 77.1097 }, // Adventure Island
    ];
    if (googleMap) {
      const lngs = visitLocations.map((station) => station.lng);
      const lats = visitLocations.map((station) => station.lat);
      googleMap.fitBounds({
        west: Math.min(...lngs),
        east: Math.max(...lngs),
        north: Math.min(...lats),
        south: Math.max(...lats),
      });

      // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      const parts = [];
      const max = 25 - 1;
      for (let i = 0; i < visitLocations.length; i = i + max) {
        parts.push(visitLocations.slice(i, i + max + 1));
      }

      // Service callback to process service results
      const service_callback = function (response, status) {
        if (status !== "OK") {
          console.log("Directions request failed due to " + status);
          return;
        }
        const renderer = new window.google.maps.DirectionsRenderer();
        renderer.setMap(map);
        renderer.setOptions({
          suppressMarkers: true,
          preserveViewport: true,
          polylineOptions: {
            strokeColor: "#FF6600", // Change the color of the route line here
          },
          // routeIndex: 0,
          // provideRouteAlternatives: false,

          // suppressPolylines: true,
        });
        renderer.setDirections(response);
      };

      // Send requests to service to get route (for stations count <= 25 only one request will be sent)
      for (let i = 0; i < parts.length; i++) {
        // Waypoints does not include first station (origin) and last station (destination)
        const waypoints = [];
        for (let j = 1; j < parts[i].length - 1; j++)
          waypoints.push({ location: parts[i][j], stopover: false });
        // Service options
        const service_options = {
          origin: parts[i][0],
          destination: parts[i][parts[i].length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
          // provideRouteAlternatives: false,
          // suppressPolylines: true,
        };
        // Send request
        const service = new window.google.maps.DirectionsService({
          provideRouteAlternatives: false,
        });
        service.route(service_options, service_callback);
      }
    }
  }, [visitLocations, googleMap]);

  useEffect(() => {
    // Set up the marker
    if (googleMap && location) {
      if (markerRef.current) {
        // Remove the previous marker if it exists
        markerRef.current.setMap(null);
      }

      // Create a new marker at the updated location
      markerRef.current = new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: googleMap,
        icon: markerIcon,
      });
    }
  }, [googleMap, location]);

  const goToCurrentLocation = () => {
    console.log(location);
    if (location) {
      googleMap.panTo({ lat: location.lat, lng: location.lng });
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div id="map" ref={mapRef} style={{ height: "100%", width: "100%" }} />;
      {selectedMarker ? (
        <div className="absolute  top-20 left-2 z-[2] flex items-end  overflow-auto">
          <section className="relative bg-white px-4 py-2 rounded-md shadow-lg w-[200px] overflow-auto ">
            <div>
              <h3>Visit No: {selectedMarker?.visitno}</h3>
              <p className="font-semibold">{selectedMarker?.customername}</p>
              <p className="text-sm ">Address: {selectedMarker?.address}</p>
            </div>

            <div
              className="absolute  z-20 top-2 right-2"
              onClick={() => setSelectedMarker(null)}
            >
              <img src={crossIcon} alt="cross" className=" w-5" />
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      <section className="absolute bottom-24 right-6">
        <img
          src={currentLocationIcon}
          alt="location"
          onClick={() => goToCurrentLocation()}
          className="w-10"
        />
        {/* <button
          onClick={() => map.panTo({ lat: location.lat, lng: location.lng })}
          className="px-3 py-1 bg-green-500 text-white"
        >
          CL
        </button> */}
      </section>
    </div>
  );
}

export default MapWithDirections;
