import { Routes, Route } from "react-router-dom";
import Attendance from "./Components/Attendance/Attendance";
import CheckInForm from "./Components/Attendance/AttenDemo";
// import Capture from "./Components/Attendance/Capture";
import CreateProfile from "./Components/createProfile";
import Pending from "./Components/Pending";
import PhoneInputField from "./Components/phoneInput";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Request from "./Components/request/Request";
import Terms from "./Components/Terms&Condition/Terms";
import ViewAttenddanceRecord from "./Components/ViewAllAttendance/ViewAttenddanceRecord";
import ViewDetails from "./Components/ViewAllAttendance/ViewDetails";
import WelcomePage from "./Components/welcomePage/WelcomePage";
import Dashboard from "./Dashboard/Dashboard";
import Report from "./Dashboard/Report/Report";
import Target from "./Dashboard/Target/Target";
import InvoiceDetails from "./Components/invoice/InvoiceDetails";
import { useEffect, useRef } from "react";
import Location from "./Components/Attendance/Location";
import Location2 from "./Components/Attendance/Location2";
import Tour from "./Components/Tour/Tour";
import MapRoute from "./Components/map/MapRoute";
import GoogleMapToutes from "./Components/map/GoogleMapRoutes";
import Gmap from "./Components/map/Gmap";
import config from "./Components/Utils/config";
import { useState } from "react";
import axios from "axios";
import { current } from "@reduxjs/toolkit";

function App() {
  const [isPunchIn, setIsPunchIn] = useState(false);
  const [isPunchOut, setIsPunchOut] = useState(false);
  // useEffect(() => {
  //   const handleContextmenu = (e) => {
  //     e.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", handleContextmenu);

  //   document.onkeydown = (e) => {
  //     // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + Shift + C, Ctrl + U
  //     if (
  //       e.keyCode === 123 ||
  //       (e.ctrlKey &&
  //         e.shiftKey &&
  //         (e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 67)) ||
  //       (e.ctrlKey && e.keyCode === 85)
  //     ) {
  //       e.preventDefault();
  //       return false;
  //     }
  //   };

  //   return function cleanup() {
  //     document.removeEventListener("contextmenu", handleContextmenu);
  //   };
  // }, []);

  const prevLocationRef = useRef(null);
  //   get location
  // const [location, setLocation] = useState({
  //   lat: null,
  //   lng: null,
  // });

  let currentLocationRef = useRef(null);

  const getCurrenttLocation = () => {
    let location = navigator.geolocation.getCurrentPosition((position) => {
      // setLocation({
      //   lat: position.coords.latitude,
      //   lng: position.coords.longitude,
      // });

      currentLocationRef.current = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });

    return location;
  };

  function watchUserLocation() {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 100000,
    };

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        // setLocation({
        //   lat: latitude,
        //   lng: longitude,
        // });

        currentLocationRef.current = {
          lat: latitude,
          lng: longitude,
        };

        console.log(latitude, longitude);
      },
      (error) => {
        console.error("Error watching user's location: ", error);
      },
      options
    );

    // Return the watchId so that it can be used to clear the watch later if needed.
    return watchId;
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const getUserCurrentStatus = async () => {
    let phoneNum = config.phoneNum;
    let date = getCurrentDate();

    try {
      let response = await axios.get(
        config.ipAddress + `/attendance/state/${phoneNum}/${date}`
      );
      let attendance = response?.data?.data?.attendance;

      setIsPunchIn(Boolean(attendance[0]?.IsPunchIn));
      setIsPunchOut(Boolean(attendance[0]?.IsPunchOut));
    } catch (error) {
      console.log(error.message);
    }
  };

  const submitCurrentLocation = async () => {
    console.log("running...submit");
    console.log(currentLocationRef.current);

    // if (
    //   prevLocationRef.current &&
    //   prevLocationRef.current.lat === location.lat &&
    //   prevLocationRef.current.lng === location.lng
    // ) {
    //   // Location hasn't changed, do nothing
    //   return;
    // }

    // prevLocationRef.current = { ...location };

    let data = {
      mobile: config.phoneNum,
      lat: currentLocationRef.current.lat,
      lang: currentLocationRef.current.lng,
    };

    console.log(data);
    if (!(currentLocationRef.current.lat && currentLocationRef.current.lng))
      return;

    try {
      console.log("hitting...");
      let response = await axios.post(
        config.ipAddress + `/position/currentposition`,
        data
      );
      console.log(response);
    } catch (error) {}
  };

  useEffect(() => {
    getUserCurrentStatus();
    getCurrenttLocation();
    watchUserLocation();

    /* let timer = setInterval(() => {
      getUserCurrentStatus();
    }, 1800000);

    return () => {
      clearInterval(timer);
    }; */
  }, []);

  /* useEffect(() => {
    console.log(isPunchIn);

    if (isPunchOut) return;

    let intervalId;
    if (isPunchIn) {
      submitCurrentLocation();

      // Set up the interval to run the function every 10 minutes (600000 milliseconds)
      intervalId = setInterval(() => {
        submitCurrentLocation();
      }, 600000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isPunchIn, isPunchOut]); */
  return (
    <>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/phone" element={<PhoneInputField />} />
        <Route path="/createprofile" element={<CreateProfile />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/dashboard/*" element={<Dashboard />}>
          <Route path="" element={<Target />} />
          <Route path="target" element={<Target />} />
          <Route path="attendance" element={<Attendance />} />
          {/* <Route path="tour" element={<Tour />} /> */}
          {/* <Route path="map" element={<MapRoute />} /> */}
          {/* <Route path="mapss" element={<GoogleMapToutes />} /> */}
          <Route path="maps" element={<Gmap />} />
        </Route>
        <Route
          path="/dashboard/target/report/:quarter/:year"
          element={<Report />}
        />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/request" element={<Request />} />
        <Route path="/attendance/record" element={<ViewAttenddanceRecord />} />
        <Route
          path="/viewdetails/:date/:punchin/:punchout"
          element={<ViewDetails />}
        />
        {/* <Route path="/demo" element={<CheckInForm />} /> */}
        {/* <Route path="/capture" element={<Capture />} /> */}
        <Route path="/invoice/:number" element={<InvoiceDetails />} />
        {/* <Route path="/location" element={<Location />} />
        <Route path="/location2" element={<Location2 />} /> */}
      </Routes>
    </>
  );
}

export default App;
