import axios from "axios";
import { useState, useEffect } from "react";
import config from "../Utils/config";

const useGlobalStateApi = () => {
  let phoneNum = localStorage.getItem("superonUserPhNum");

  const [globalPunchIn, setGlobalPunchIn] = useState(false);
  const [isError, setIsError] = useState(false);

  const getGlobalAttendanceStatus = async () => {
    try {
      let response = await axios.get(
        config.ipAddress + `/globalattendanceacheckin/${phoneNum}`
      );
      console.log(response?.data?.data?.logintype);
      let loginType = response?.data?.data?.logintype;
      console.log(loginType);
      if (loginType === "attendanace") {
        setGlobalPunchIn(true);
      }
      if (loginType === "tour") {
        setGlobalPunchIn(true);
      }
      if (loginType === "none") {
        setGlobalPunchIn(false);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    getGlobalAttendanceStatus();
  }, []);

  return [globalPunchIn, isError];
};

export default useGlobalStateApi;
