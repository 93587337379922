// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const CellTowerGeolocation = () => {
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           "https://ap1.unwiredlabs.com/v2/process.php",
//           {
//             params: {
//               token: "pk.a42095a74baa81b7c3781a3aee266eb2",
//               mcc: "123", // Mobile Country Code
//               mnc: "456", // Mobile Network Code
//               cells: [
//                 {
//                   lac: "789", // Location Area Code
//                   cid: "101112", // Cell Tower ID
//                 },
//               ],
//               radio: "gsm", // Radio Type (gsm, cdma, wcdma, lte)
//             },
//           }
//         );
//         const { lat, lon } = response.data;
//         console.log(response);
//         setLatitude(lat);
//         setLongitude(lon);
//       } catch (error) {
//         console.error("Error fetching geolocation:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   let getLocation = async () => {
//     let response = await axios.get(
//       "https://ap1.unwiredlabs.com/v2/search?token=pk.a42095a74baa81b7c3781a3aee266eb2&q=kolkata"
//     );
//     console.log(response);
//   };
//   getLocation();
//   return (
//     <div>
//       Latitude: {latitude}
//       <br />
//       Longitude: {longitude}
//     </div>
//   );
// };

// export default CellTowerGeolocation;

// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const LocationComponent = () => {
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get("http://ip-api.com/json");
//         const { lat, lon } = response.data;
//         setLatitude(lat);
//         setLongitude(lon);
//       } catch (error) {
//         console.error("Error fetching geolocation:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       Latitude: {latitude}
//       <br />
//       Longitude: {longitude}
//     </div>
//   );
// };

// export default LocationComponent;

import React, { useEffect, useState } from "react";

const DistanceCalculator = () => {
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const successCallback = (position) => {
      const { latitude, longitude } = position.coords;
      setCurrentLocation({ latitude, longitude });
    };

    const errorCallback = (error) => {
      console.error("Error getting current location:", error);
    };

    const interval = setInterval(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback,
          options
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <div>
        {currentLocation ? (
          <p>
            Latitude: {currentLocation.latitude}, Longitude:{" "}
            {currentLocation.longitude}
          </p>
        ) : (
          <p>Fetching current location...</p>
        )}
      </div>
    </div>
  );
};

export default DistanceCalculator;
