import React, { useState, useEffect, useRef, useCallback } from "react";
import plusIcon from "../../assets/plusIcon2.svg";
import closeIcon from "../../assets/close.svg";
import clockIcon from "../../assets/clock.png";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/icon-cancel.svg";
import clockIcon2 from "../../assets/clockIcon.svg";
import loadingGif from "../../assets/loading-white.gif";
import crossIcon from "../../assets/cross.svg";
import resetLogo from "../../assets/reset.svg";
import cameraSwitch from "../../assets/cameraRotate.svg";
import cameraCapture from "../../assets/cameraCapture.svg";
import upArrow from "../../assets/upArrow-white.svg";
import downArrow from "../../assets/downArrow-white.svg";
import clockIconGray from "../../assets/clockIcon-gray.png";
import clockIconRed from "../../assets/clock-red.svg";
import Webcam from "react-webcam";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import config from "../Utils/config";
import { useFormAction, useNavigate } from "react-router-dom";
import PunchPopup from "../popupModal/PunchPopup";
import Popup from "../popupModal/Popup";
import ErrorPopup from "../popupModal/ErrorPopup";
// import useGlobalStateApi from "../customHook/useGlobalStateApi";

function Attendance() {
  const navigate = useNavigate();
  // const [globalPunchIn, isError] = useGlobalStateApi();
  let phoneNum = localStorage.getItem("superonUserPhNum");

  const [date, setDate] = useState("15/08/1947");
  const [timePunchIn, setTimePunchIn] = useState("00:00");
  const [timePunchOut, setTimePunchOut] = useState("00:00");

  // camera modal
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [isCameraModalOpenMobile, setIsCameraModalOpenMobile] = useState(false);

  const [photo, setPhoto] = useState("");
  const [odometerPhoto, setOdometerPhoto] = useState("");
  const fileInputRef = useRef(null);
  const [odometerDetails, setOdometerDetails] = useState({
    meterReading: "",
    remark: "",
  });

  const [showMeterInput, setShowMeterInput] = useState(true);

  // transport mode
  const [isCarSelect, setIsCarSelect] = useState(false);
  const [isBikeSelect, setIsBikeSelect] = useState(false);
  const [isOtherSelect, setIsOtherSelect] = useState(false);
  const [isTrainSelect, setIsTrainSelect] = useState(false);
  const [isDealerCarSelect, setIsDealerCarSelect] = useState(false);
  const [isBusSelect, setIsBusSelect] = useState(false);
  const [isJointVisitSelect, setIsJointVisitSelect] = useState(false);
  const [isTourSelected, setIsTourSelected] = useState(false);

  const [picture, setPicture] = useState("");
  const webcamRef = React.useRef(null);
  const [facingMode, setFacingMode] = useState("environment");

  // checkin and Out

  const [customDealerName, setCustomDealerName] = useState("");
  const [dealerPunchInTime, setDealerPunchInTime] = useState("");
  const [dealerPunchOutTime, setDealerPunchOutTime] = useState("");
  const [dealerCheckInandOutList, setDealerCheckInandOutList] = useState([]);
  const intervalRef = useRef(null);
  const [dealerPunchInName, setDealerPunchInName] = useState("");
  const [uuid, setDealerUuid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [punchMessage, setPunchMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [message, setMessage] = useState("");

  // Dealer list
  const [dealerList, setDealerList] = useState([]);
  const [dealerListCopy, setDealerListCopy] = useState([]);

  //  punch in and out state
  const [isPunchIn, setIsPunchIN] = useState(false);
  const [isPunchOut, setIsPunchOut] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);

  const checkOutButtonRef = useRef(null);

  const [holdCustomerLastVisit, setCustomerHoldLastVisit] = useState(null);
  const [holdDealerLastVisit, setDealerHoldLastVisit] = useState(null);
  const [holdVisitId, setHoldVisitId] = useState(null);
  const [isAnyCustomerFieldCheckIn, setIsAnyCustomerFieldCheckIn] =
    useState(false);
  const [isAnyDealerFieldCheckIn, setIsAnyDealerFieldCheckIn] = useState(false);

  const [holdVehicleType, setVehicleType] = useState(null);

  // visit One
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showVisitOne, setShowVisitOne] = useState(false);
  const [dealerNameVisitOne, setDealerNameVisitOne] = useState("");
  const [showCheckOutOne, setShowCheckOutOne] = useState(false);
  const [showDealerListVisitOne, setShowDealerListVisitOne] = useState(false);
  const [remarkVisitOne, setRemarkVisitOne] = useState("");
  const [visitListOne, setVistListOne] = useState([]);

  // visit two
  const [showVisitTwo, setShowVisitTwo] = useState(false);
  const [dealerNameVisitTwo, setDealerNameVisitTwo] = useState("");
  const [showCheckOutTwo, setShowCheckOutTwo] = useState(false);
  const [showDealerListVisitTwo, setShowDealerListVisitTwo] = useState(false);
  const [remarkVisitTwo, setRemarkVisitTwo] = useState("");
  const [visitListTwo, setVistListTwo] = useState([]);

  // visit three
  const [showVisitThree, setShowVisitThree] = useState(false);
  const [dealerNameVisitThree, setDealerNameVisitThree] = useState("");
  const [showCheckOutThree, setShowCheckOutThree] = useState(false);
  const [showDealerListVisitThree, setShowDealerListVisitThree] =
    useState(false);
  const [remarkVisitThree, setRemarkVisitThree] = useState("");
  const [visitListThree, setVistListThree] = useState([]);

  // visit four
  const [showVisitFour, setShowVisitFour] = useState(false);
  const [dealerNameVisitFour, setDealerNameVisitFour] = useState("");
  const [showCheckOutFour, setShowCheckOutFour] = useState(false);
  const [showDealerListVisitFour, setShowDealerListVisitFour] = useState(false);
  const [remarkVisitFour, setRemarkVisitFour] = useState("");
  const [visitListFour, setVistListFour] = useState([]);

  // visit five
  const [showVisitFive, setShowVisitFive] = useState(false);
  const [dealerNameVisitFive, setDealerNameVisitFive] = useState("");
  const [showCheckOutFive, setShowCheckOutFive] = useState(false);
  const [showDealerListVisitFive, setShowDealerListVisitFive] = useState(false);
  const [remarkVisitFive, setRemarkVisitFive] = useState("");
  const [visitListFive, setVistListFive] = useState([]);

  // visit six
  const [showVisitSix, setShowVisitSix] = useState(false);
  const [dealerNameVisitSix, setDealerNameVisitSix] = useState("");
  const [showCheckOutSix, setShowCheckOutSix] = useState(false);
  const [showDealerListVisitSix, setShowDealerListVisitSix] = useState(false);
  const [remarkVisitSix, setRemarkVisitSix] = useState("");
  const [visitListSix, setVistListSix] = useState([]);

  // visit seven
  const [showVisitSeven, setShowVisitSeven] = useState(false);
  const [dealerNameVisitSeven, setDealerNameVisitSeven] = useState("");
  const [showCheckOutSeven, setShowCheckOutSeven] = useState(false);
  const [showDealerListVisitSeven, setShowDealerListVisitSeven] =
    useState(false);
  const [remarkVisitSeven, setRemarkVisitSeven] = useState("");
  const [visitListSeven, setVistListSeven] = useState([]);

  // visit eight
  const [showVisitEight, setShowVisitEight] = useState(false);
  const [dealerNameVisitEight, setDealerNameVisitEight] = useState("");
  const [showCheckOutEight, setShowCheckOutEight] = useState(false);
  const [showDealerListVisitEight, setShowDealerListVisitEight] =
    useState(false);
  const [remarkVisitEight, setRemarkVisitEight] = useState("");
  const [visitListEight, setVistListEight] = useState([]);

  // visit punchout
  const [showVisitPunchOut, setShowVisitPunchOut] = useState(false);

  // Location
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const getCurrenttLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          // setAccuracy(position.coords.accuracy);
          console.log(position.coords);
        },
        (error) => {
          console.log("Error:", error.message);
        },
        {
          enableHighAccuracy: true,
        }
      );

      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log("Error:", error.message);
        },

        {
          enableHighAccuracy: true,
        }
      );

      // Clear the watch position no longer need it
      // navigator.geolocation.clearWatch(watchId);
    } else {
      console.log("Geolocation API is not supported");
    }
  };

  // blockUser
  useEffect(() => {
    let phoneNum = localStorage.getItem("superonUserPhNum");

    if (!phoneNum) {
      navigate("/phone");
    }
  }, []);

  useEffect(() => {
    getCurrenttLocation();
  });

  useEffect(() => {
    let interval = setInterval(() => {
      getCurrenttLocation();
    }, 50000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // visit one functionality 👌👌👌
  const handleDealerChangeVisitOne = (e) => {
    setDealerNameVisitOne(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitOne = (dealerName) => {
    console.log(dealerName);

    setDealerNameVisitOne(dealerName);

    setShowDealerListVisitOne(false);
  };

  // dealer checkIn visit One
  const handleCheckInClickVisitOne = async (id) => {
    getCurrenttLocation();

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }

    if (!(dealerNameVisitOne || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }
    if (isAnyDealerFieldCheckIn) {
      setMessage(`Please fill dealer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }
    // if (dealerName === "1") {
    //   if (!customDealerName) return alert("Enter Custom Dealer Name");
    // }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);
    //     setShowCheckOutOne(true);
    //     setDealerPunchInTime(getTime());
    // setDealerPunchInName()

    // localStorage.setItem("dealerPunchInTime", getTime());
    // localStorage.setItem("showChechOut", true);
    // localStorage.setItem("superonDealerName", customDealerName || dealerName);
    // send data to server

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitOne === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitOne) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitOne,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      serialno: "1",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit two functionality 👌👌👌
  const handleDealerChangeVisitTwo = (e) => {
    setDealerNameVisitTwo(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitTwo = (dealerName) => {
    console.log(dealerName);

    setDealerNameVisitTwo(dealerName);

    setShowDealerListVisitTwo(false);
  };

  // dealer checkIn visit two
  const handleCheckInClickVisitTwo = async (id) => {
    getCurrenttLocation();

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }

    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 1 first!");
    //   setShowErrorModal(true);
    //   return;
    // }
    if (isAnyDealerFieldCheckIn) {
      setMessage(`Please fill dealer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }

    if (holdCustomerLastVisit + 1 !== id) {
      setMessage(
        `Please fill customer visit ${holdCustomerLastVisit + 1} first`
      );
      setShowErrorModal(true);
      return;
    }

    if (!(dealerNameVisitTwo || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    // if (dealerName === "1") {
    //   if (!customDealerName) return alert("Enter Custom Dealer Name");
    // }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    // setShowCheckIn(false);
    setShowLoading(true);
    //     setShowCheckOutOne(true);
    //     setDealerPunchInTime(getTime());
    // setDealerPunchInName()

    // localStorage.setItem("dealerPunchInTime", getTime());
    // localStorage.setItem("showChechOut", true);
    // localStorage.setItem("superonDealerName", customDealerName || dealerName);
    // send data to server

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitTwo === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitTwo) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitTwo,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      serialno: id + "",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit three functionality 👌👌👌
  const handleDealerChangeVisitThree = (e) => {
    setDealerNameVisitThree(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitThree = (dealerName) => {
    setDealerNameVisitThree(dealerName);

    setShowDealerListVisitThree(false);
  };

  // dealer checkIn visit three
  const handleCheckInClickVisitThree = async (id) => {
    getCurrenttLocation();

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }

    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 2 first!");
    //   setShowErrorModal(true);
    //   return;
    // }

    console.log(isAnyDealerFieldCheckIn, holdCustomerLastVisit, id);
    if (isAnyDealerFieldCheckIn) {
      setMessage(`Please fill dealer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }

    if (holdCustomerLastVisit + 1 !== id) {
      setMessage(
        `Please fill customer visit ${holdCustomerLastVisit + 1} first`
      );
      setShowErrorModal(true);
      return;
    }
    if (!(dealerNameVisitThree || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    // if (dealerName === "1") {
    //   if (!customDealerName) return alert("Enter Custom Dealer Name");
    // }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);
    //     setShowCheckOutOne(true);
    //     setDealerPunchInTime(getTime());
    // setDealerPunchInName()

    // localStorage.setItem("dealerPunchInTime", getTime());
    // localStorage.setItem("showChechOut", true);
    // localStorage.setItem("superonDealerName", customDealerName || dealerName);
    // send data to server

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitThree === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitThree) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitThree,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      serialno: id + "",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit four functionality 👌👌👌
  const handleDealerChangeVisitFour = (e) => {
    setDealerNameVisitFour(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitFour = (dealerName) => {
    setDealerNameVisitFour(dealerName);

    setShowDealerListVisitFour(false);
  };

  // dealer checkIn visit four
  const handleCheckInClickVisitFour = async (id) => {
    getCurrenttLocation();

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }

    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 3 first!");
    //   setShowErrorModal(true);
    //   return;
    // }
    if (isAnyDealerFieldCheckIn) {
      setMessage(`Please fill dealer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }

    if (holdCustomerLastVisit + 1 !== id) {
      setMessage(
        `Please fill customer visit ${holdCustomerLastVisit + 1} first`
      );
      setShowErrorModal(true);
      return;
    }

    if (!(dealerNameVisitFour || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitFour === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitFour) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitFour,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      serialno: id + "",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit five functionality 👌👌👌
  const handleDealerChangeVisitFive = (e) => {
    setDealerNameVisitFive(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitFive = (dealerName) => {
    setDealerNameVisitFive(dealerName);

    setShowDealerListVisitFive(false);
  };

  // dealer checkIn visit five
  const handleCheckInClickVisitFive = async (id) => {
    getCurrenttLocation();

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }

    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 4 first!");
    //   setShowErrorModal(true);
    //   return;
    // }
    if (isAnyDealerFieldCheckIn) {
      setMessage(`Please fill dealer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }

    if (holdCustomerLastVisit + 1 !== id) {
      setMessage(
        `Please fill customer visit ${holdCustomerLastVisit + 1} first`
      );
      setShowErrorModal(true);
      return;
    }

    if (!(dealerNameVisitFive || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitFive === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitFive) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitFive,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      serialno: id + "",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit six functionality 👌👌👌
  const handleDealerChangeVisitSix = (e) => {
    setDealerNameVisitSix(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitSix = (dealerName) => {
    setDealerNameVisitSix(dealerName);
    setShowDealerListVisitSix(false);
  };

  // visit six
  const handleCheckInClickVisitSix = async (id) => {
    getCurrenttLocation();
    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 5 first!");
    //   setShowErrorModal(true);
    //   return;
    // }

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }
    if (isAnyDealerFieldCheckIn) {
      setMessage(`Please fill dealer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }

    if (holdCustomerLastVisit + 1 !== id) {
      setMessage(
        `Please fill customer visit ${holdCustomerLastVisit + 1} first`
      );
      setShowErrorModal(true);
      return;
    }
    if (!(dealerNameVisitSix || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitSix === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitSix) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitSix,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      serialno: id + "",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit seven functionality 👌👌👌 dealer 1
  const handleDealerChangeVisitSeven = (e) => {
    setDealerNameVisitSeven(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitSeven = (dealerName) => {
    setDealerNameVisitSeven(dealerName);
    setShowDealerListVisitSeven(false);
  };

  // dealer checkIn visit seven
  const handleCheckInClickVisitSeven = async (id) => {
    getCurrenttLocation();
    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 5 first!");
    //   setShowErrorModal(true);
    //   return;
    // }

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }
    if (isAnyCustomerFieldCheckIn) {
      setMessage(`Please fill customer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }

    if (!(dealerNameVisitSeven || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitSeven === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitSix) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitSeven,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      type: "dealer",
      serialno: "1",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // visit eight functionality 👌👌👌 dealer 2
  const handleDealerChangeVisitEight = (e) => {
    setDealerNameVisitEight(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const handleDealerNameVisitEight = (dealerName) => {
    setDealerNameVisitEight(dealerName);
    setShowDealerListVisitEight(false);
  };

  // dealer checkIn visit eight
  const handleCheckInClickVisitEight = async (id, dealerNum) => {
    getCurrenttLocation();
    // if (Number(holdLastVisit) + 1 !== id) {
    //   setMessage("Please fill visit 5 first!");
    //   setShowErrorModal(true);
    //   return;
    // }

    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      return;
    }
    if (isAnyCustomerFieldCheckIn) {
      setMessage(`Please fill customer visit ${holdVisitId} first`);
      setShowErrorModal(true);
      return;
    }
    if (holdDealerLastVisit + 1 !== dealerNum) {
      setMessage(`Please fill dealer visit ${holdDealerLastVisit + 1} first`);
      setShowErrorModal(true);
      return;
    }

    if (!(dealerNameVisitEight || customDealerName)) {
      setMessage("Please Enter Dealer/Customer Name!");
      setShowErrorModal(true);
      return;
    }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setShowCheckIn(false);
    setShowLoading(true);

    let phoneNum = localStorage.getItem("superonUserPhNum");
    let dealerId = localStorage.getItem("superonDealerId");

    let selectedDealer = dealerList.filter((list) => {
      return dealerNameVisitEight === list.dealername;
    });

    console.log(selectedDealer);
    if (!dealerNameVisitEight) {
      dealerId = "0";
    }
    let data = {
      mobile: phoneNum,
      dealername: customDealerName || dealerNameVisitEight,
      uuid: uuidv4(), //'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      address: "address",
      latitude: latitude + "",
      longitude: longitude + "",
      dealerid: selectedDealer[0]?.dealerid || dealerId,
      spid: selectedDealer[0]?.spid || "0",
      visitid: id + "",
      type: "dealer",
      serialno: "2",
    };
    console.log(data);
    // localStorage.setItem("uuid", data.uuid);

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/newdealer/punchin`,
        data
      );
      console.log(response);
      // alert(response.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    getUserAttendanceState();
    getDealerCheckInandOutList();
  };

  // handle cancel visit all
  const handleCancelClickVisit = async (id) => {
    // let uuid = localStorage.getItem("uuid");
    setShowCheckIn(false);
    if (id === 1) {
      setShowCheckOutOne(false);
      setDealerNameVisitOne("");
    }

    if (id === 2) {
      setShowCheckOutTwo(false);
      setDealerNameVisitTwo("");
    }
    if (id === 3) {
      setShowCheckOutThree(false);
      setDealerNameVisitThree("");
    }
    if (id === 4) {
      setShowCheckOutFour(false);
      setDealerNameVisitFour("");
    }
    if (id === 5) {
      setShowCheckOutFive(false);
      setDealerNameVisitFive("");
    }
    if (id === 6) {
      setShowCheckOutSix(false);
      setDealerNameVisitSix("");
    }
    if (id === 7) {
      setShowCheckOutSeven(false);
      setDealerNameVisitSeven("");
    }
    if (id === 8) {
      setShowCheckOutEight(false);
      setDealerNameVisitEight("");
    }
    localStorage.removeItem("showChechOut");
    localStorage.removeItem("superonDealerName");
    setCustomDealerName("");

    try {
      let response = await axios.get(
        config.ipAddress + `/deletecancelvisit/${uuid}`
      );
      console.log(response);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }
    getDealerCheckInandOutList();
  };

  // dealer checkout all 🚀🚀🚀
  const handleCheckOutClick = async (id) => {
    getCurrenttLocation();
    const currentTime = getTime();
    let isValidTime = isTimeDifferenceLessThanFiveMinutes(
      dealerPunchInTime,
      currentTime
    );
    console.log(isValidTime, currentTime, dealerPunchInTime);
    if (isValidTime) {
      setMessage("Please Check-out After Meeting Ends");
      setShowErrorModal(true);
      return;
    }

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    setDealerPunchOutTime(getTime());
    // console.log(dealerName, customDealerName, remark, dealerPunchInTime);

    // send data to server
    let phoneNum = localStorage.getItem("superonUserPhNum");
    // let uuid = localStorage.getItem("uuid");

    let data;

    if (id === 1) {
      if (!(phoneNum && remarkVisitOne)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitOne,
        latitude: latitude + "",
        longitude: longitude + "",
        serialno: id + "",
      };
    }

    if (id === 2) {
      console.log("running");
      if (!(phoneNum && remarkVisitTwo)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitTwo,
        latitude: latitude + "",
        longitude: longitude + "",
        serialno: id + "",
      };
    }

    if (id === 3) {
      console.log("running");
      if (!(phoneNum && remarkVisitThree)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitThree,
        latitude: latitude + "",
        longitude: longitude + "",
        serialno: id + "",
      };
    }
    if (id === 4) {
      console.log("running");
      if (!(phoneNum && remarkVisitFour)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitFour,
        latitude: latitude + "",
        longitude: longitude + "",
        serialno: id + "",
      };
    }
    if (id === 5) {
      console.log("running");
      if (!(phoneNum && remarkVisitFive)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitFive,
        latitude: latitude + "",
        longitude: longitude + "",
        serialno: id + "",
      };
    }

    if (id === 6) {
      console.log("running");
      if (!(phoneNum && remarkVisitSix)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitSix,
        latitude: latitude + "",
        longitude: longitude + "",
        serialno: id + "",
      };
    }

    if (id === 7) {
      console.log("running");
      if (!(phoneNum && remarkVisitSeven)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitSeven,
        latitude: latitude + "",
        longitude: longitude + "",
        type: "dealer",
        serialno: id + "",
      };
    }

    if (id === 8) {
      console.log("running");
      if (!(phoneNum && remarkVisitEight)) {
        setMessage("Please add remarks!");
        setShowErrorModal(true);
        return;
      }

      data = {
        mobile: phoneNum,
        uuid: uuid,
        remark: remarkVisitEight,
        latitude: latitude + "",
        longitude: longitude + "",
        type: "dealer",
        serialno: id + "",
      };
    }

    setShowLoading(true);

    localStorage.removeItem("showChechOut");
    localStorage.removeItem("superonDealerName");

    console.log(data);

    // if (lastVisit) {
    //   if (isPunchIn) {
    //     openCameraModal();
    //     setShowLoading(false);
    //     setShowCheckOut(true);

    //     return;
    //   }
    // }
    if (id === 1) {
      setShowCheckOutOne(false);
      setDealerNameVisitOne("");
      setCustomDealerName("");
      setRemarkVisitOne("");
    }

    if (id === 2) {
      setShowCheckOutTwo(false);
      setDealerNameVisitTwo("");
      setCustomDealerName("");
      setRemarkVisitTwo("");
    }
    if (id === 3) {
      setShowCheckOutThree(false);
      setDealerNameVisitThree("");
      setCustomDealerName("");
      setRemarkVisitThree("");
    }
    if (id === 4) {
      setShowCheckOutFour(false);
      setDealerNameVisitFour("");
      setCustomDealerName("");
      setRemarkVisitFour("");
    }
    if (id === 5) {
      setShowCheckOutFive(false);
      setDealerNameVisitFive("");
      setCustomDealerName("");
      setRemarkVisitFive("");
    }

    if (id === 6) {
      setShowCheckOutSix(false);
      setDealerNameVisitSix("");
      setCustomDealerName("");
      setRemarkVisitSix("");
    }

    try {
      let res = await axios.post(
        config.ipAddress + `/newdealer/punchout`,
        data
      );
      console.log(res.data.message);
      // alert(res.data.message);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }

    setIsModalOpen2(true);

    getDealerCheckInandOutList();
    getDealers();
  };

  // getDistance bwtween checkin and checkout 🚀🚀
  const getDistanceDealerCheckOut = async (lat, lon) => {
    if (!(lat && lon)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      return;
    }

    let data = {
      mobile: phoneNum,
      lat: lat,
      lang: lon,
    };
    let response = await axios.post(
      config.ipAddress + `/dealercheckoutdistance`,
      data
    );
    let distance = response?.data?.data;
    return distance;
  };

  const scrollDropdownToMiddle = (id) => {
    let ele = document.getElementById(`visit${id}`);
    if (ele) {
      //   const dropdownHeight = ele.offsetHeight;
      //   const windowHeight = window.innerHeight;
      //   const dropdownTop = ele.offsetTop;
      //   const scrollToPosition =
      //     dropdownTop - windowHeight / 2 + dropdownHeight / 2;

      //   window.scrollTo({
      //     top: scrollToPosition,
      //     behavior: "smooth",
      //   });
      // }
      // ele.scrollIntoView({ behavior: "smooth", block: "start" });
      ele.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      window.scrollBy(0, -ele.offsetHeight);
    }
  };

  const getDealers = async () => {
    // setShowLoading(true);
    let phoneNum = localStorage.getItem("superonUserPhNum");

    try {
      let res = await axios.get(config.ipAddress + `/dealers/${phoneNum}`);
      let allData = res?.data?.data;
      allData.sort((a, b) => {
        if (a.dealername.toLowerCase() < b.dealername.toLowerCase()) {
          return -1;
        } else if (a.dealername.toLowerCase() > b.dealername.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      setDealerList(allData);
      setDealerListCopy(allData);
      // setShowLoading(false);
    } catch (error) {
      // setShowLoading(false);
    }
  };

  const getDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear(); // 2023
    const month = currentDate.getMonth() + 1; // January is 0, so add 1
    const day = currentDate.getDate(); // 4

    const dateString = `${day < 10 ? "0" : ""}${day}/${
      month < 10 ? "0" : ""
    }${month}/${year}`;
    setDate(dateString);
    console.log(dateString); // "2023-04-04"
  };

  const getTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const twelveHourFormat = hours % 12 || 12;

    const timeString = `${twelveHourFormat}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${amOrPm}`;

    return timeString;
  };

  const getTimePunchIn = () => {
    const timeString = getTime();
    setTimePunchIn(timeString);
    console.log(timeString); // "11:13 PM"
    return timeString;
  };

  const getTimePunchOut = () => {
    const timeString = getTime();
    setTimePunchOut(timeString);
    console.log(timeString); // "11:13 PM"

    return timeString;
  };

  useEffect(() => {
    getDealerCheckInandOutList();
    getDate();

    const currentDate = new Date().getDate();
    localStorage.setItem("currentDate", currentDate);
  }, []);

  // find time difference
  const isTimeDifferenceLessThanFiveMinutes = (previousTime, currentTime) => {
    if (!(previousTime && currentTime)) return;
    const previousDate = new Date();
    const currentdate = new Date();

    // Set the previous time
    const [previousHours, previousMinutes, previousAmOrPm] =
      previousTime.split(/:|\s/);
    const previousTwelveHourFormat = parseInt(previousHours);
    const previousIsPm = previousAmOrPm === "PM";

    previousDate.setHours(
      previousTwelveHourFormat +
        (previousIsPm && previousTwelveHourFormat !== 12 ? 12 : 0)
    );
    previousDate.setMinutes(parseInt(previousMinutes));

    // Set the current time
    const [currentHours, currentMinutes, currentAmOrPm] =
      currentTime.split(/:|\s/);
    const currentTwelveHourFormat = parseInt(currentHours);
    const currentIsPm = currentAmOrPm === "PM";

    currentdate.setHours(
      currentTwelveHourFormat +
        (currentIsPm && currentTwelveHourFormat !== 12 ? 12 : 0)
    );
    currentdate.setMinutes(parseInt(currentMinutes));

    const timeDifference = (currentdate - previousDate) / (1000 * 60); // Difference in minutes

    return timeDifference < 5;
  };

  useEffect(() => {
    let showCheckedOut = localStorage.getItem("showChechOut");

    if (!showCheckedOut) return;

    setShowCheckOutOne(showCheckedOut);
  }, [showCheckIn]);

  //   punch in and out functionality 🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀
  const handlePunchCancelandSuccess = (status) => {
    if (status) {
      if (isPunchIn) {
        handlePunchOut();
        setIsModalOpen(false);
        return;
      }
      handlePunchIn();
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const handlePhotoChange = (event) => {
    if (!fileInputRef.current.files[0]) return;
    const file2 = fileInputRef.current.files[0];

    const file = event.target.files[0];
    const image = new Image();

    image.onload = function () {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      // Reduce image quality by compressing it
      const compressedDataURL = canvas.toDataURL("image/jpeg", 0.5);

      const originalSize = (file.size / 1024).toFixed(2); // in kilobytes
      const compressedSize = (compressedDataURL.length / 1024).toFixed(2); // in kilobytes

      console.log("Original Size:", originalSize + " KB");
      console.log("Compressed Size:", compressedSize + " KB");

      // Set the selected image
      setPhoto(compressedDataURL);
    };

    image.src = URL.createObjectURL(file);
    setOdometerPhoto(file2);
    // const photoUrl = URL.createObjectURL(file);
    // setPhoto(photoUrl);
  };

  const handleResetOdometer = () => {
    setPhoto("");
    setOdometerPhoto("");
    setPicture("");
    fileInputRef.current.value = "";
    setOdometerDetails({
      meterReading: "",
      remark: "",
    });
    setIsCameraModalOpen(false);
  };

  const handleResetOdometerMobile = () => {
    setPhoto("");
    setOdometerPhoto("");
    setPicture("");
    fileInputRef.current.value = "";
    setOdometerDetails({
      meterReading: "",
      remark: "",
    });
    setIsCameraModalOpenMobile(false);
  };

  const handleOpenCamera = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (odometerPhoto) {
      setIsCameraModalOpenMobile(true);
    }
  }, [odometerPhoto]);

  function convertBase64ToFile(base64Data, filename) {
    // Extract base64 data (remove data URI prefix)
    const base64String = base64Data.replace(
      /^data:image\/(png|jpeg|jpg);base64,/,
      ""
    );

    // Convert base64 to binary data
    const binaryData = atob(base64String);

    // Create a Uint8Array to hold the binary data
    const length = binaryData.length;
    const uint8Array = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob object from Uint8Array
    const blob = new Blob([uint8Array.buffer], { type: "image/jpeg" });

    // Create a File object from Blob
    const file = new File([blob], filename);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("files", file);

    return formData;
  }

  // handleOdometer Submit 🚀🚀🚀
  const handleOdometerSubmit = async () => {
    // console.log(odometerPhoto, odometerDetails);
    if (isPunchIn) {
      let distance = await getDistanceDealerCheckOut(latitude, longitude);
      console.log(distance);
      if (Number(distance) > 2) {
        setMessage(
          "please punch out within 2 kms of your last Check-In Location."
        );
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }
    }

    if (!(picture || odometerPhoto)) {
      setMessage("Please add odometer picture!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    if (
      !(
        isCarSelect ||
        isBikeSelect ||
        isOtherSelect ||
        isTrainSelect ||
        isDealerCarSelect ||
        isJointVisitSelect ||
        isBusSelect ||
        isTourSelected
      )
    ) {
      setMessage("Please select transport mode!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    if (showMeterInput) {
      if (!odometerDetails.meterReading) {
        setMessage("Please add odometer reading!");
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }
    }

    let vehicle = "";
    if (isCarSelect) {
      vehicle = "car";
    }
    if (isBikeSelect) {
      vehicle = "bike";
    }
    if (isOtherSelect) {
      vehicle = "other";
    }
    if (isTrainSelect) {
      vehicle = "train";
    }
    if (isDealerCarSelect) {
      vehicle = "dealercar";
    }
    if (isJointVisitSelect) {
      vehicle = "jointvisit";
    }
    if (isTourSelected) {
      vehicle = "tour";
    }
    if (isBusSelect) {
      vehicle = "bus";
    }

    let data;

    if (window.innerWidth <= 767) {
      // for mobile
      // let form = new FormData();
      // form.append(`files`, odometerPhoto);
      // data = form;

      // Convert the data URL to a Blob
      const blob = await fetch(photo).then((response) => response.blob());

      // Create a FormData object to send the image as part of the payload
      const formData = new FormData();
      formData.append("files", blob, "odometer.jpg");

      data = formData;
    } else {
      // for Desktop
      data = convertBase64ToFile(picture, "image.JPG");
    }

    try {
      setShowLoading(true);
      let response = await axios({
        method: "post",
        url:
          config.ipAddress +
          `/impressed?mobile=${phoneNum}&comment=${
            odometerDetails.remark
          }&uploaddate=${getCurrentDate()}&meterreading=${
            odometerDetails.meterReading || "0"
          }&vehicletype=${vehicle}`,
        data: data,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response?.data?.code);
      setShowLoading(false);
      handleResetOdometer();
      if (response?.data?.code === 200) {
        if (isPunchIn) {
          handlePunchOut();
          setIsCameraModalOpen(false);
          setIsCameraModalOpenMobile(false);
          return;
        }
        handlePunchIn();
      }
      setIsCameraModalOpen(false);
      setIsCameraModalOpenMobile(false);
      setPicture("");
    } catch (error) {
      setMessage("Network error");
      setShowErrorModal(true);
      setIsCameraModalOpen(false);
      setIsCameraModalOpenMobile(false);
      setShowLoading(false);
      return;
    }
  };

  const handleUserLogout = async () => {
    if (!isPunchIn) {
      setMessage("Please punch in first!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    } else {
      let distance = await getDistanceDealerCheckOut(latitude, longitude);
      console.log(distance);
      if (!distance) {
        setMessage(
          "please punch out within 2 kms of your last Check-In Location."
        );
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      } else {
        openCameraModal(9);
      }
    }
  };

  const openCameraModal = (id) => {
    getCurrenttLocation();

    setShowLoading(true);

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    // if (holdCustomerLastVisit + 1 !== id) {
    //   setMessage(`Please fill visit ${holdCustomerLastVisit + 1} first`);
    //   setShowErrorModal(true);
    //   return;
    // }

    if (id === 9) {
      if (!isPunchIn) {
        setMessage("Please punch in first!");
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }

      if (isAnyCustomerFieldCheckIn) {
        setMessage(`Please fill customer visit ${holdVisitId} first`);
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }

      if (isAnyDealerFieldCheckIn) {
        setMessage(`Please fill dealer visit ${holdVisitId} first`);
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }

      //if (window.innerWidth <= 480) {
	  if (window.innerWidth <= 767) {	  
        // for mobile
        setShowLoading(false);
        handleOpenCamera();
        return;
      } else {
        // for Desktop
        setShowLoading(false);
        setIsCameraModalOpen(true);
      }
    } else {
      if (window.innerWidth <= 767) {
        // for mobile
        setShowLoading(false);
        handleOpenCamera();
      } else {
        // for Desktop
        setShowLoading(false);
        setIsCameraModalOpen(true);
      }
    }
  };

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  // punch in 🚀🚀🚀🚀🚀🚀
  const handlePunchIn = async () => {
    getCurrenttLocation();

    setShowLoading(true);

    setIsPunchIN(true);

    // sending punchIn details to server 🚀🚀🚀
    let data = {
      mobile: phoneNum,
      punchtype: "attendance",
      latitude: latitude + "",
      longitude: longitude + "",
    };

    try {
      console.log(data);
      let response = await axios.post(
        config.ipAddress + `/attendance/punchin`,
        data
      );
      console.log(response.data.message);
      // alert(response.data.message);

      getUserAttendanceState();
      setShowAddButton(true);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
  };

  const convertTime = (timeString) => {
    // const timeString = "13:02:45";
    if (!timeString) return;
    const [hours, minutes] = timeString.split(":").map(Number);

    let amPm = "AM";
    let formattedHours = hours % 12;
    if (formattedHours === 0) {
      formattedHours = 12;
    }
    if (hours >= 12) {
      amPm = "PM";
    }

    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
    console.log(formattedTime);

    return formattedTime;
  };

  const handlePunchOut = async () => {
    getCurrenttLocation();

    // let punchinOutTime = getTimePunchOut();

    // localStorage.setItem("isPunchOut", true);
    // localStorage.setItem("punchOutTime", punchinOutTime);
    setShowLoading(true);

    if (!(latitude && longitude)) {
      setMessage("Please enable your location!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
    setShowAddButton(true);
    setIsPunchOut(true);
    // sending punchIn details to server 🚀🚀🚀
    let phoneNum = localStorage.getItem("superonUserPhNum");
    let data = {
      mobile: phoneNum,
      punchtype: "attendance",
      latitude: latitude + "",
      longitude: longitude + "",
      // latitude: "22.3673163",
      // longitude: "88.5679291",
    };
    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/attendance/punchout`,
        data
      );
      console.log(response?.data?.message);
      // alert(response.data.message);

      // if (Number(response?.data?.message)) {
      //   // checkOutButtonRef.current.click();
      //   handleCheckOutClick(6);
      // }
      setShowLoading(false);

      setIsModalOpen2(true);
      setShowAddButton(false);
      getTimePunchOut();
      getDealerCheckInandOutList();
      getDealers();
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }
  };

  // get Current Date
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  // get Dealer check in and Out list
  const getDealerCheckInandOutList = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    let data = {
      mobile: phoneNum,
      visitdate: getCurrentDate(),
    };

    let response = await axios.post(config.ipAddress + `/newvisitbydate`, data);

    let allCustomerVisitData = response?.data?.data?.visit;
    let allDealerVisitData = response?.data?.data?.dealervisit;

    console.log(allCustomerVisitData);

    let filterCustomerData = allCustomerVisitData.filter((item) => {
      return item.punchouttime !== null;
    });

    setCustomerHoldLastVisit(filterCustomerData.length);

    if (allCustomerVisitData.length !== filterCustomerData.length) {
      setIsAnyCustomerFieldCheckIn(true);
    } else {
      setIsAnyCustomerFieldCheckIn(false);
    }

    let filterDealerData = allDealerVisitData.filter((item) => {
      return item.punchouttime !== null;
    });

    setDealerHoldLastVisit(filterDealerData.length);

    if (allDealerVisitData.length !== filterDealerData.length) {
      setIsAnyDealerFieldCheckIn(true);
    } else {
      setIsAnyDealerFieldCheckIn(false);
    }
    let allVisitList = [...filterCustomerData, ...filterDealerData];

    setDealerCheckInandOutList(allVisitList);
    let visitOneList = filterCustomerData.filter((item) => {
      return item?.serialno === "1";
    });
    setVistListOne(visitOneList);

    let visitTwoList = filterCustomerData.filter((item) => {
      return item?.serialno === "2";
    });
    setVistListTwo(visitTwoList);

    let visitThreeList = filterCustomerData.filter((item) => {
      return item?.serialno === "3";
    });
    setVistListThree(visitThreeList);

    let visitFourList = filterCustomerData.filter((item) => {
      return item?.serialno === "4";
    });
    setVistListFour(visitFourList);

    let visitFiveList = filterCustomerData.filter((item) => {
      return item?.serialno === "5";
    });
    setVistListFive(visitFiveList);

    let visitSixList = filterCustomerData.filter((item) => {
      return item?.serialno === "6";
    });

    setVistListSix(visitSixList);

    let visitSevenList = filterDealerData.filter((item) => {
      return item?.serialno === "1";
    });

    setVistListSeven(visitSevenList);

    let visitEightList = filterDealerData.filter((item) => {
      return item?.serialno === "2";
    });

    setVistListEight(visitEightList);

    return;

    // setHoldLastVisit(filterData.length);

    // if (allData.length !== filterData.length) {
    //   console.log("not equal");
    //   setIsAnyFieldCheckIn(true);
    // } else {
    //   setIsAnyFieldCheckIn(false);
    // }
  };

  const convertTimeTo12HourFormat = (time) => {
    if (!time) return;
    let hours = parseInt(time.substring(0, 2));
    let minutes = time.substring(3, 5);
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  // console.log(convertTimeTo12HourFormat("11:25:24")); // Output: "11:25 AM"

  const convertIntoCamelCase = (name) => {
    let formattedName = name.charAt(0) + name.slice(1).toLowerCase();

    return formattedName;
  };

  // holding punchin and out time 🚀🚀🚀
  useEffect(() => {
    localStorage.setItem("userPunchInTime", timePunchIn);
    localStorage.setItem("userPunchOutTime", timePunchOut);
  }, [timePunchIn, timePunchOut]);

  const getUserAttendanceState = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    let date = getCurrentDate();

    let response = await axios.get(
      config.ipAddress + `/attendance/state/${phoneNum}/${date}`
    );

    console.log(response?.data?.data);
    console.log(response?.data?.data?.attendance);

    let attendance = response?.data?.data?.attendance;
    let dailyActivities = response?.data?.data?.dailyactivity;

    setIsPunchIN(Boolean(attendance[0]?.IsPunchIn));
    setTimePunchIn(convertTime(attendance[0]?.timePunchIn));
    // console.log(Boolean(attendance[0]?.IsPunchIn));
    setShowAddButton(Boolean(attendance[0]?.IsPunchIn));

    setIsPunchOut(Boolean(attendance[0]?.IsPunchOut));
    setTimePunchOut(convertTime(attendance[0]?.timePunchOut));
    // console.log(attendance[0]?.IsPunchOut);
    if (Boolean(attendance[0]?.IsPunchOut)) {
      setShowAddButton(!Boolean(attendance[0]?.IsPunchOut));
    }

    console.log(attendance[0]?.vehicle);
    if (attendance[0]?.vehicle) {
      let transportType = attendance[0]?.vehicle;
      if (transportType === "car") {
        setIsCarSelect(true);
      }
      if (transportType === "bike") {
        setIsBikeSelect(true);
      }
      if (transportType === "other") {
        setIsOtherSelect(true);
      }
      if (transportType === "train") {
        setIsTrainSelect(true);
      }
      if (transportType === "dealercar") {
        setIsDealerCarSelect(true);
      }
      if (transportType === "bus") {
        setIsBusSelect(true);
      }
      if (transportType === "jointvisit") {
        setIsJointVisitSelect(true);
      }
      if (transportType === "tour") {
        setIsTourSelected(true);
      }
      setVehicleType(attendance[0]?.vehicle);
    }

    setHoldVisitId(Number(dailyActivities[0]?.serialno));

    if (Boolean(dailyActivities[0]?.visitid === String(1))) {
      setShowCheckOutOne(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitOne(true);
    }

    if (Boolean(dailyActivities[0]?.visitid === String(2))) {
      setShowCheckOutTwo(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitTwo(true);
    }
    if (Boolean(dailyActivities[0]?.visitid === String(3))) {
      setShowCheckOutThree(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitThree(true);
    }
    if (Boolean(dailyActivities[0]?.visitid === String(4))) {
      setShowCheckOutFour(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitFour(true);
    }
    if (Boolean(dailyActivities[0]?.visitid === String(5))) {
      setShowCheckOutFive(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitFive(true);
    }
    if (Boolean(dailyActivities[0]?.visitid === String(6))) {
      setShowCheckOutSix(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitSix(true);
    }

    if (Boolean(dailyActivities[0]?.visitid === String(7))) {
      setShowCheckOutSeven(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitSeven(true);
    }

    if (Boolean(dailyActivities[0]?.visitid === String(8))) {
      setShowCheckOutEight(Boolean(dailyActivities[0]?.showcheckout));
      setShowVisitEight(true);
    }

    setDealerPunchInTime(convertTime(dailyActivities[0]?.punchIn));
    setDealerPunchInName(dailyActivities[0]?.dealername);

    setDealerUuid(dailyActivities[0]?.uuid);
    console.log(dailyActivities[0]?.uuid);
  };

  useEffect(() => {
    // getUserAttendanceState();
    // getDealerCheckInandOutList();
  });
  useEffect(() => {
    getUserAttendanceState();
    getDealerCheckInandOutList();
    getDealers();
  }, []);

  useEffect(() => {
    setShowMeterInput(true);

    if (
      isDealerCarSelect ||
      isJointVisitSelect ||
      isTourSelected ||
      isTrainSelect ||
      isBusSelect
    ) {
      console.log("running>>");
      setShowMeterInput(false);
    }

    if (isCarSelect || isBikeSelect) {
      setShowMeterInput(true);
    }
  }, [
    isDealerCarSelect,
    isJointVisitSelect,
    isTourSelected,
    isTrainSelect,
    isBusSelect,
  ]);

  return (
    <div className=" bg-[#fafafa]  h-[89.2%] overflow-auto">
      <main className="  min-h-full px-3 pt-3 pb-6">
        {/* Section 1 */}
        <div className="rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white">
          <section className="flex justify-between items-center  border-b pb-4">
            <div className="flex gap-2 pt-4 ml-4 ">
              <div className="bg-blue-light w-[2.5px] rounded-full"></div>
              <div className="">
                <h1 className="text-lg ">
                  {isPunchOut ? "Attendance Marked" : "Mark Attendance"}
                </h1>
                <p>{date}</p>
              </div>
            </div>
            {/* {showAddButton && (
              <div className="mr-4">
                <img
                  src={plusIcon}
                  alt="plus-icon"
                  className="w-5"
                  onClick={handleAddClick}
                />
              </div>
            )} */}
          </section>
          <section className="text-cente  py-3">
            {/* <span>{date}</span> <br />
          <h1 className="uppercase font-semibold">
            {isPunchOut ? "Attendance Marked" : "Mark Attendance"}
          </h1> */}
            {/* <div className="ml-6 mb-1 flex justify-between">
              <p className=" text-[13px] font-semibold">When leaving home</p>
            </div> */}
            <div className="flex justify-between gap-y-2   w-full  bg-white ">
              {/* Punch In */}
              {!isPunchIn && (
                <div className="ml-7">
                  <div>
                    <p className="mb-[6px]  text-center text-[12px] font-bold ">
                      When leaving home
                    </p>
                  </div>
                  <div className="flex  justify-center items-center gap-2 bg-[#FF6262]  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl ">
                    <img src={clockIcon2} alt="clock=logo " />
                    <button
                      onClick={() => openCameraModal()}
                      // onClick={() => handlePunchIn()}
                      className=" text-[13px] text-white"
                    >
                      Punch In
                    </button>
                  </div>
                </div>
              )}

              {isPunchIn && (
                <div className="flex flex-col items-start  pl-3 w-[43%]">
                  <div>
                    <p className="mb-[6px] ml-4 text-center text-[12px] font-bold ">
                      When leaving home
                    </p>
                  </div>

                  <div className="flex items-start justify-start gap-x-2 ml-[10px] bg-white w-[130px] px-2  rounded-[6px] ">
                    <div className=" flex flex-col justify-start items-start">
                      <div className="flex items-start gap-x-2">
                        <img
                          src={clockIconRed}
                          alt="clock=logo"
                          className="w-4 h-4 mt-[3px]"
                        />
                        <div className="flex flex-col items-start justify-start text-sm">
                          <span className="  text-[#FF6262]">Punch In:</span>
                          <span className="  text-[#FF6262] ">
                            {timePunchIn}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Punch Out */}
              {/* 
              {!isPunchOut && (
                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl ">
                  <img src={clockIcon2} alt="clock=logo" />
                  <button
                    // onClick={() => handlePunchOut()}
                    onClick={() => {
                      if (isPunchIn) {
                        openCameraModal();
                      }
                    }}
                    className={` text-[13px] ${
                      isPunchIn ? "" : "text-[#AAAAAA]"
                    } `}
                  >
                    Punch Out
                  </button>
                </div>
              )} */}
              {/* {isPunchOut && (
                <div className=" border-r border-dashed border-gray-300"></div>
              )} */}

              {isPunchOut && (
                <div className="flex flex-col items-start justify-center pr-3 border-l border-dashed border-gray-300 w-[57%]">
                  <div>
                    <p className="mb-1 ml-4 text-start text-[12px] font-bold ">
                      When leaving last customer
                    </p>
                  </div>
                  <div className="flex items-start justify-start gap-x-2 ml-[10px] bg-white w-[130px] px-2  rounded-[6px] ">
                    <div className=" flex flex-col justify-start items-start">
                      <div className="flex items-start gap-x-2">
                        <img
                          src={clockIconRed}
                          alt="clock=logo"
                          className="w-4 h-4 mt-[3px]"
                        />
                        <div className="flex flex-col items-start justify-start text-sm">
                          <span className=" text-[#FF6262]">Punch Out:</span>
                          <span className="  text-[#FF6262] ">
                            {timePunchOut}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>

        {/* section 2 */}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handlePhotoChange}
          style={{ display: "none" }}
        />
        <main className="bg-slate-light mt-3  overflow-auto rounded-[10px]">
          <section className=" bg-[#46c842]  box-border px-4 py-3 rounded-[10px] border-[1px] shadow-6xl">
            <div className="flex gap-2">
              <div className="bg-white w-[9px] rounded-full"></div>
              <div className="">
                <h1 className="text-md text-white">
                  Please Note :{" "}
                  <span className="">
                    App only allows{" "}
                    <span className="font-semibold uppercase text-red-500">
                      final punch out
                    </span>{" "}
                    within{" "}
                    <span className="font-semibold uppercase"> 2 km</span> of
                    your last check In location of customer/dealer. Accordingly
                    for fuel reimbursement back to home please carry out{" "}
                    <span className="font-semibold uppercase text-red-500">
                      final punch out{" "}
                    </span>{" "}
                    after last check In and{" "}
                    <span className="font-semibold">NOT</span> after reaching
                    home.
                  </span>
                </h1>
                {/* <p className="text-sm font-semibold text-white">
                  Punch out within 2 kms of your last Check-In Location.
                </p> */}
              </div>
            </div>
          </section>
        </main>
        {!isPunchOut && (
          <section>
            {/* Visit one 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitOne(!showVisitOne);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);

                  // scrollDropdownToMiddle(1);
                }}
                className="flex  justify-between bg-blue-light w-full py-2 px-4 rounded-t-[4px]"
              >
                <h1 className="text-white font-thin">Customer Visit 1</h1>

                {/* {showVisitOne ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitOne ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitOne ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit1"
              >
                <section
                  className={`bg-gray-100 px-2 py-4 rounded-b-[4px] overflow-hidden transition-all duration-300 ease-in-out`}
                >
                  {visitListOne.length === 0 ? (
                    <main>
                      {!showCheckOutOne && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Customer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitOne}
                              onChange={(e) => handleDealerChangeVisitOne(e)}
                              onFocus={() => setShowDealerListVisitOne(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitOne(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitOne && (
                              <div className="w-full absolute top-9 left-0 z-20 bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitOne(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching customer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() => handleCheckInClickVisitOne(1)}
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutOne
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutOne && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {/* {localStorage.getItem("superonDealerName")} */}
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(1)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitOne}
                                onChange={(e) =>
                                  setRemarkVisitOne(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex justify-center items-center gap-2 mt-2 mr-1">
                              {/* <section className="flex items-center gap-x-2 ">
                      <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                   
                        <button
                          // ref={checkOutButtonRef}
                          // onClick={() => handlePunchOut()}
                          onClick={() => handleCancelClickVisitOne()}
                          className={`inline-block text-sm mx-auto`}
                        >
                          Cancel
                        </button>
                      </div>
                    </section> */}

                              <section className="flex flex-col items-center gap-x-2">
                                <div className="mb-2 w-full flex justify-center">
                                  <p className="text-[12px] font-bold text-center">
                                    When leaving customer
                                  </p>
                                </div>
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  {/* <img src={clockIcon2} alt="clock=logo" /> */}
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(1)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListOne.length
                        ? visitListOne.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <div className="flex gap-2 bg-white ">
                                    <div className="bg-blue-light w-[2.5px] rounded-full"></div>
                                  </div>
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className=" gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* Visit two 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitTwo(!showVisitTwo);
                  setShowVisitOne(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);

                  scrollDropdownToMiddle(2);
                }}
                className="flex justify-between bg-blue-light w-full py-2 px-4 rounded-t-[4px]"
              >
                <h1 className="text-white font-thin">Customer Visit 2</h1>

                {/* {showVisitTwo ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitTwo ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitTwo ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit2"
              >
                <section
                  id="visit2"
                  className="bg-gray-100 px-2 py-4 rounded-b-[4px]"
                >
                  {visitListTwo.length === 0 ? (
                    <main>
                      {!showCheckOutTwo && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Customer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitTwo}
                              onChange={(e) => handleDealerChangeVisitTwo(e)}
                              onFocus={() => setShowDealerListVisitTwo(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitTwo(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitTwo && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitTwo(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching customer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() => handleCheckInClickVisitTwo(2)}
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutTwo
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutTwo && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(2)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitTwo}
                                onChange={(e) =>
                                  setRemarkVisitTwo(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 mr-1">
                              <div className=" w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving customer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(2)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListTwo.length
                        ? visitListTwo.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* Visit three 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitThree(!showVisitThree);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);

                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);

                  scrollDropdownToMiddle(3);
                }}
                className="flex justify-between bg-blue-light w-full py-2 px-4 rounded-t-[4px]"
              >
                <h1 className="text-white font-thin">Customer Visit 3</h1>

                {/* {showVisitThree ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitThree ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitThree ? "max-h-[300px]" : "max-h-0"
                }`}
              >
                <section
                  id="visit3"
                  className="bg-gray-100 px-2 py-4 rounded-b-[4px]"
                >
                  {visitListThree.length === 0 ? (
                    <main>
                      {!showCheckOutThree && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Customer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitThree}
                              onChange={(e) => handleDealerChangeVisitThree(e)}
                              onFocus={() => setShowDealerListVisitThree(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitThree(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitThree && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitThree(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching customer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() => handleCheckInClickVisitThree(3)}
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutThree
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutThree && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(3)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitThree}
                                onChange={(e) =>
                                  setRemarkVisitThree(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 ">
                              <div className="w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving customer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(3)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListThree.length
                        ? visitListThree.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* Visit four 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitFour(!showVisitFour);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);
                  scrollDropdownToMiddle(4);
                }}
                className="flex justify-between bg-blue-light w-full py-2 px-4 rounded-t-[4px]"
              >
                <h1 className="text-white font-thin">Customer Visit 4</h1>

                {/* {showVisitFour ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitFour ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitFour ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit4"
              >
                <section className="bg-gray-100 px-2 py-4 rounded-b-[4px]">
                  {visitListFour.length === 0 ? (
                    <main>
                      {!showCheckOutFour && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Customer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitFour}
                              onChange={(e) => handleDealerChangeVisitFour(e)}
                              onFocus={() => setShowDealerListVisitFour(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitFour(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitFour && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitFour(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching customer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() => handleCheckInClickVisitFour(4)}
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutFour
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutFour && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(4)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitFour}
                                onChange={(e) =>
                                  setRemarkVisitFour(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 mr-1">
                              <div className="w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving customer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(4)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListFour.length
                        ? visitListFour.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* Visit five 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitFive(!showVisitFive);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);

                  scrollDropdownToMiddle(4);
                  scrollDropdownToMiddle(5);
                }}
                className="flex justify-between bg-blue-light w-full py-2 px-4 rounded-t-[4px]"
                id="visit5"
              >
                <h1 className="text-white font-thin">Customer Visit 5</h1>

                {/* {showVisitFive ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitFive ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitFive ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit5"
              >
                <section className="bg-gray-100 px-2 py-4 rounded-b-[4px]">
                  {visitListFive.length === 0 ? (
                    <main>
                      {!showCheckOutFive && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Customer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitFive}
                              onChange={(e) => handleDealerChangeVisitFive(e)}
                              onFocus={() => setShowDealerListVisitFive(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitFive(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitFive && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitFive(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching customer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() => handleCheckInClickVisitFive(5)}
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutFive
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutFive && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(5)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitFive}
                                onChange={(e) =>
                                  setRemarkVisitFive(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 mr-1">
                              <div className="w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving customer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(5)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListFive.length
                        ? visitListFive.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* Visit Six 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitSix(!showVisitSix);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);
                  scrollDropdownToMiddle(6);
                }}
                className="flex justify-between bg-blue-light w-full py-2 px-4 rounded-t-[4px]"
                id="visit6"
              >
                <h1 className="text-white font-thin">Customer Visit 6</h1>

                {/* {showVisitFive ? (
                  <img src={upArrow} alt="xpand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitSix ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitSix ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit6"
              >
                <section className="bg-gray-100 px-2 py-4 rounded-b-[4px]">
                  {visitListSix.length === 0 ? (
                    <main>
                      {!showCheckOutSix && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Customer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitSix}
                              onChange={(e) => handleDealerChangeVisitSix(e)}
                              onFocus={() => setShowDealerListVisitSix(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitSix(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitSix && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitSix(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching customer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() => handleCheckInClickVisitSix(6)}
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutSix
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutSix && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(6)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitSix}
                                onChange={(e) =>
                                  setRemarkVisitSix(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 mr-1">
                              <div className="w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving customer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(6)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListSix.length
                        ? visitListSix.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* visit seven dealer visit 1 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitSeven(!showVisitSeven);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitEight(false);
                  setShowVisitPunchOut(false);
                  scrollDropdownToMiddle(7);
                }}
                className="flex justify-between bg-blue-500 w-full py-2 px-4 rounded-t-[4px]"
                id="visit7"
              >
                <h1 className="text-white font-thin">Dealer Visit 1</h1>

                {/* {showVisitFive ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitSeven ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitSeven ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit7"
              >
                <section className="bg-gray-100 px-2 py-4 rounded-b-[4px]">
                  {visitListSeven.length === 0 ? (
                    <main>
                      {!showCheckOutSeven && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Dealer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitSeven}
                              onChange={(e) => handleDealerChangeVisitSeven(e)}
                              onFocus={() => setShowDealerListVisitSeven(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitSeven(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitSeven && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitSeven(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching dealer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() =>
                                  handleCheckInClickVisitSeven(7, 1)
                                }
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutSeven
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutSeven && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(7)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitSeven}
                                onChange={(e) =>
                                  setRemarkVisitSeven(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 mr-1">
                              <div className="w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving dealer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(7)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListSeven.length
                        ? visitListSeven.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* visit seven dealer visit 1 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitEight(!showVisitEight);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitPunchOut(false);
                  scrollDropdownToMiddle(8);
                }}
                className="flex justify-between bg-blue-500 w-full py-2 px-4 rounded-t-[4px]"
                id="visit8"
              >
                <h1 className="text-white font-thin">Dealer Visit 2</h1>

                {/* {showVisitFive ? (
                  <img src={upArrow} alt="expand" className="w-6" />
                ) : (
                  <img src={downArrow} alt="close" className="w-6" />
                )} */}
                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitEight ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitEight ? "max-h-[300px]" : "max-h-0"
                }`}
                id="visit8"
              >
                <section className="bg-gray-100 px-2 py-4 rounded-b-[4px]">
                  {visitListEight.length === 0 ? (
                    <main>
                      {!showCheckOutEight && (
                        <div className="bg-white px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                          <div className="w-full mb-4 relative">
                            <input
                              type="text"
                              placeholder="Dealer Name"
                              className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-blue-light"
                              value={dealerNameVisitEight}
                              onChange={(e) => handleDealerChangeVisitEight(e)}
                              onFocus={() => setShowDealerListVisitEight(true)}
                              onBlur={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                  setShowDealerListVisitEight(false);
                                }, 0);
                              }}
                            />
                            {showDealerListVisitEight && (
                              <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[0px] max-h-[105px] rounded-b-md overflow-y-auto">
                                {
                                  dealerList.length
                                    ? dealerList.map((list, index) => {
                                        return (
                                          <p
                                            key={index}
                                            onClick={() =>
                                              handleDealerNameVisitEight(
                                                list.dealername
                                              )
                                            }
                                            className="px-3 text-sm py-1 hover:bg-blue-300 "
                                          >
                                            {list.dealername}
                                          </p>
                                        );
                                      })
                                    : ""
                                  // <h1 className="text-sm text-center py-1">
                                  //   Delaer not found
                                  // </h1>
                                }
                              </div>
                            )}
                          </div>

                          <div className="text-center">
                            <div className="">
                              <p className="text-[12px] font-bold mb-2">
                                When reaching dealer
                              </p>
                            </div>
                            <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                              <button
                                // onClick={() => handlePunchOut()}
                                onClick={() =>
                                  handleCheckInClickVisitEight(8, 2)
                                }
                                className={`inline-block text-sm mx-auto`}
                              >
                                Check In
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* checkout */}

                      <div
                        className={`${
                          showCheckIn || showCheckOutEight
                            ? "py-4 px-5 border-[1px]"
                            : ""
                        } w-full bg-white rounded-[10px]  shadow-6xl  border-gray-300 `}
                      >
                        {showCheckOutEight && (
                          <div className="">
                            <div className="  pr-1">
                              <div className="flex justify-between items-end">
                                <h3 className="mt-1">
                                  {convertIntoCamelCase(dealerPunchInName)}
                                </h3>
                                <div className="   w-[30px] h-[25px]  rounded-[4px] flex items-center justify-center border-[1px] border-gray-300">
                                  <img
                                    src={crossIcon}
                                    alt="submit-logo"
                                    className="w-4 cursor-pointer"
                                    onClick={() => handleCancelClickVisit(8)}
                                  />
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-2">
                                <img
                                  src={clockIconGray}
                                  alt="clock-logo"
                                  className="w-4 h-4"
                                />
                                <span className="text-sm">
                                  {dealerPunchInTime}
                                </span>
                              </div>

                              <textarea
                                type="text"
                                // id={`remarks-${index}`}
                                // name={`remarks-${index}`}
                                className=" w-full px-2 mt-3   border-[1px] text-sm border-gray-400  focus:outline-none focus:border-blue-light rounded-[4px]"
                                // cols="40"
                                rows="3"
                                placeholder="Add Remarks"
                                value={remarkVisitEight}
                                onChange={(e) =>
                                  setRemarkVisitEight(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex flex-col justify-center items-center gap-2 mt-2 mr-1">
                              <div className="w-full flex justify-center">
                                <p className="text-[12px] font-bold text-center">
                                  When leaving dealer
                                </p>
                              </div>
                              <section className="flex items-center gap-x-2">
                                <div className="flex  justify-center items-center gap-2  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto">
                                  <button
                                    // ref={checkOutButtonRef}
                                    // onClick={() => handlePunchOut()}
                                    onClick={() => handleCheckOutClick(8)}
                                    className={`inline-block text-sm mx-auto`}
                                  >
                                    Check Out
                                  </button>
                                </div>
                              </section>
                            </div>
                          </div>
                        )}
                      </div>
                    </main>
                  ) : (
                    <section className="bg-[#fff] rounded-[10px]">
                      {visitListEight.length
                        ? visitListEight.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" bg-white  rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300 "
                              >
                                <div className="flex flex-col gap-1 py-3   ">
                                  <h1 className="text-md  text-orange-light">
                                    <span className="text-black ">
                                      {convertIntoCamelCase(item.dealername)}
                                    </span>
                                  </h1>
                                  <div className="  gap-2">
                                    <img
                                      src={clockIconGray}
                                      alt="clock-logo"
                                      className="w-4 inline-block"
                                    />
                                    <p className=" text-sm text-black inline-block ml-2">
                                      {convertTimeTo12HourFormat(
                                        item.punchintime
                                      )}{" "}
                                      -{" "}
                                      {convertTimeTo12HourFormat(
                                        item.punchouttime
                                      )}
                                    </p>
                                  </div>
                                  <p className=" text-base text-black  ">
                                    Remarks :{" "}
                                    <span className="font-medium   ">
                                      {item.remark}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                          ""}
                    </section>
                  )}
                </section>
              </div>
            </section>

            {/* Final punch out 🚀🚀🚀🚀🚀🚀🚀🚀 */}
            <section className="mt-4">
              <div
                onClick={() => {
                  setShowVisitPunchOut(!showVisitPunchOut);
                  setShowVisitOne(false);
                  setShowVisitTwo(false);
                  setShowVisitThree(false);
                  setShowVisitFour(false);
                  setShowVisitFive(false);
                  setShowVisitSix(false);
                  setShowVisitSeven(false);
                  setShowVisitEight(false);
                  scrollDropdownToMiddle(9);
                }}
                className="flex justify-between bg-[#FF6262] w-full py-2 px-4 rounded-t-[4px]"
              >
                <h1 className="text-white font-thin">
                  Final Punch Out (Last Customer/Dealer)
                </h1>

                <img
                  src={downArrow}
                  alt="close"
                  className={`w-6 transform ${
                    showVisitPunchOut ? "rotate-180" : "rotate-0"
                  } transition-transform duration-300 ease-in-out`}
                />
              </div>

              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  showVisitPunchOut ? "max-h-[250px]" : "max-h-0"
                }`}
                id="visit9"
              >
                <section className="bg-gray-100 px-2 py-4 rounded-b-[4px] flex justify-center ">
                  <div className="bg-white w-full flex flex-col justify-center items-center px-2 py-4 rounded-[10px] border-[1px] border-gray-300 ">
                    <p className="text-[12px] font-bold mb-2">
                      When leaving last customer/dealer
                    </p>
                    <div className=" items-center flex  justify-center gap-2 bg-[#FF6262]  px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl ml-[10px] ">
                      <img src={clockIcon2} alt="clock=logo " />
                      <button
                        onClick={() => openCameraModal(9)}
                        // onClick={() => handlePunchIn()}
                        className=" text-[13px] text-white"
                      >
                        Punch Out
                      </button>
                    </div>

                    <h1 className="text-sm text-black mt-3 -leading-3">
                      Please Note :{" "}
                      <span className="">
                        App only allows{" "}
                        <span className="font-semibold uppercase text-red-500">
                          final punch out
                        </span>{" "}
                        within{" "}
                        <span className="font-semibold uppercase"> 2 km</span>{" "}
                        of your last check In location of customer/dealer.
                        Accordingly for fuel reimbursement back to home please
                        carry out{" "}
                        <span className="font-semibold uppercase text-red-500">
                          final punch out{" "}
                        </span>{" "}
                        after last check In and{" "}
                        <span className="font-semibold">NOT</span> after
                        reaching home.
                      </span>
                    </h1>
                  </div>
                </section>
              </div>
            </section>
          </section>
        )}

        {isPunchOut && (
          <section className="bg-[#fff] mt-4  ">
            {dealerCheckInandOutList.length
              ? dealerCheckInandOutList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" bg-white mt-4 rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300"
                    >
                      <div className="flex flex-col gap-1 py-3   ">
                        <div className="flex gap-2 bg-white ">
                          <div className="bg-blue-light w-[2.5px] rounded-full"></div>
                          <div className="">
                            <h1 className="text-md ">Visit {index + 1}</h1>
                          </div>
                        </div>
                        <h1 className="text-md  text-orange-light">
                          <span className="text-black ml-3">
                            {convertIntoCamelCase(item.dealername)}
                          </span>
                        </h1>
                        <div className="  ml-3 gap-2">
                          <img
                            src={clockIconGray}
                            alt="clock-logo"
                            className="w-4 inline-block"
                          />
                          <p className=" text-sm text-black inline-block ml-2">
                            {convertTimeTo12HourFormat(item.punchintime)} -{" "}
                            {convertTimeTo12HourFormat(item.punchouttime)}
                          </p>
                        </div>
                        <p className=" text-base text-black  ml-3">
                          Remarks :{" "}
                          <span className="font-medium   ">{item.remark}</span>
                        </p>
                      </div>
                    </div>
                  );
                })
              : // <h1 className="text-lg font-bold ml-8 py-3">No Data to Display!</h1>
                ""}
          </section>
        )}
      </main>

      {isModalOpen ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <PunchPopup
            setIsModalOpen={setIsModalOpen}
            message={punchMessage}
            handlePunchCancelandSuccess={handlePunchCancelandSuccess}
            // handlePunchOutCancelandSuccess={handlePunchOutCancelandSuccess}
          />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showLoading && (
        <div
          className="absolute z-40  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[70px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-black absolute top-0 opacity-75 z-30"
        ></div>
      )}

      {isModalOpen2 ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <Popup setIsModalOpen={setIsModalOpen2} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen2 ? (
        <div
          onClick={() => setIsModalOpen2(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showErrorModal ? (
        <div
          className="absolute z-40  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {showErrorModal ? (
        <div
          onClick={() => setShowErrorModal(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-30"
        ></div>
      ) : (
        ""
      )}

      {/*Odometer Image capture Mobile 🚀🚀🚀 */}
      {isCameraModalOpenMobile && (
        <section className="bg-white px-4 py-4 absolute z-20 h-[80%] w-[96dvw] text-center  left-[50%] top-[12%] mr-0 mb-0 ml-[-48vw] border-[1px] rounded-[10px] border-gray-300 shadow-md overflow-auto">
          <div className="relative">
            <div className="flex gap-2 mb-2">
              <div className="bg-blue-light w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Add Odometer Picture</h1>
              </div>
            </div>

            {!photo && (
              // <button onClick={handleOpenCamera}>Capture Odometer</button>
              <label
                onClick={handleOpenCamera}
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full py-3 mt-3 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100"
              >
                <div class="flex flex-col items-center justify-center py-5">
                  <svg
                    aria-hidden="true"
                    className="w-8 mb-3 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 ">
                    <span class="font-semibold">Click here to capture</span>
                  </p>
                </div>
              </label>
            )}

            {photo && (
              <div className="mt-3">
                <div className="p-2 border-[1px] border-gray-300 rounded-md">
                  <img
                    src={photo}
                    alt="odometer-photo"
                    className="w-full h-[220px]"
                  />
                </div>
              </div>
            )}

            <div className="mt-2">
              <p className="text-sm text-start text-[#757575]">
                Transport Mode
              </p>
              <div className="flex gap-x-6 gap-y-1 justify-start flex-wrap pl-0.5">
                <label
                  htmlFor="car"
                  className="flex items-center gap-x-2 min-w-[50px]"
                >
                  <input
                    type="checkbox"
                    name="car"
                    id=""
                    value={isCarSelect}
                    checked={isCarSelect}
                    onChange={(e) => {
                      setIsCarSelect(e.target.checked);
                      setIsBikeSelect(false);
                      setIsOtherSelect(false);
                      setIsTrainSelect(false);
                      setIsDealerCarSelect(false);
                      setIsBusSelect(false);
                      setIsJointVisitSelect(false);
                      setIsTourSelected(false);
                    }}
                    // onClick={() => {
                    //   setIsBikeSelect(false);
                    //   setIsOtherSelect(false);
                    // }}

                    disabled={isPunchIn && true}
                  />
                  Car
                </label>
                <label
                  htmlFor="bike"
                  className="flex items-center gap-x-2 min-w-[54px]"
                >
                  <input
                    value={isBikeSelect}
                    checked={isBikeSelect}
                    onChange={(e) => {
                      setIsBikeSelect(e.target.checked);
                      setIsCarSelect(false);
                      setIsOtherSelect(false);
                      setIsTrainSelect(false);
                      setIsDealerCarSelect(false);
                      setIsBusSelect(false);
                      setIsJointVisitSelect(false);
                      setIsTourSelected(false);
                    }}
                    // onClick={() => {
                    //   setIsCarSelect(false);
                    //   setIsOtherSelect(false);
                    // }}
                    type="checkbox"
                    name="bike"
                    disabled={isPunchIn && true}
                  />
                  Bike
                </label>

                <label
                  htmlFor="dealercar"
                  className="flex items-center gap-x-2 min-w-[95px]"
                >
                  <input
                    type="checkbox"
                    name="dealercar"
                    id=""
                    value={isDealerCarSelect}
                    checked={isDealerCarSelect}
                    onChange={(e) => {
                      setIsDealerCarSelect(e.target.checked);
                      setIsTrainSelect(false);
                      setIsCarSelect(false);
                      setIsBikeSelect(false);
                      setIsOtherSelect(false);
                      setIsBusSelect(false);
                      setIsJointVisitSelect(false);
                      setIsTourSelected(false);
                    }}
                    disabled={isPunchIn && true}
                  />
                  Dealer Car
                </label>

                <label htmlFor="train" className="flex items-center gap-x-2">
                  <input
                    type="checkbox"
                    name="train"
                    id=""
                    value={isTrainSelect}
                    checked={isTrainSelect}
                    onChange={(e) => {
                      setIsTrainSelect(e.target.checked);
                      setIsCarSelect(false);
                      setIsBikeSelect(false);
                      setIsOtherSelect(false);
                      setIsDealerCarSelect(false);
                      setIsBusSelect(false);
                      setIsJointVisitSelect(false);
                      setIsTourSelected(false);
                    }}
                    disabled={isPunchIn && true}
                  />
                  Train
                </label>

                <label
                  htmlFor="bus"
                  className="flex items-center gap-x-2 min-w-[50px]"
                >
                  <input
                    type="checkbox"
                    name="bus"
                    id=""
                    value={isBusSelect}
                    checked={isBusSelect}
                    onChange={(e) => {
                      setIsBusSelect(e.target.checked);
                      setIsDealerCarSelect(false);
                      setIsTrainSelect(false);
                      setIsCarSelect(false);
                      setIsBikeSelect(false);
                      setIsOtherSelect(false);
                      setIsJointVisitSelect(false);
                      setIsTourSelected(false);
                    }}
                    disabled={isPunchIn && true}
                  />
                  Bus
                </label>

                <label
                  htmlFor="tour"
                  className="flex items-center gap-x-2 min-w-[54px]"
                >
                  <input
                    type="checkbox"
                    name="tour"
                    id=""
                    value={isTourSelected}
                    checked={isTourSelected}
                    onChange={(e) => {
                      setIsTourSelected(e.target.checked);
                      setIsJointVisitSelect(false);
                      setIsBusSelect(false);
                      setIsDealerCarSelect(false);
                      setIsTrainSelect(false);
                      setIsCarSelect(false);
                      setIsBikeSelect(false);
                      setIsOtherSelect(false);
                    }}
                    disabled={isPunchIn && true}
                  />
                  Tour
                </label>

                <label
                  htmlFor="jointvisit"
                  className="flex items-center gap-x-2  min-w-[95px]"
                >
                  <input
                    type="checkbox"
                    name="jointvisit"
                    id=""
                    value={isJointVisitSelect}
                    checked={isJointVisitSelect}
                    onChange={(e) => {
                      setIsJointVisitSelect(e.target.checked);
                      setIsBusSelect(false);
                      setIsDealerCarSelect(false);
                      setIsTrainSelect(false);
                      setIsCarSelect(false);
                      setIsBikeSelect(false);
                      setIsOtherSelect(false);
                      setIsTourSelected(false);
                    }}
                    disabled={isPunchIn && true}
                  />
                  Joint Visit
                </label>

                <label htmlFor="other" className="flex items-center gap-x-2">
                  <input
                    value={isOtherSelect}
                    checked={isOtherSelect}
                    onChange={(e) => {
                      setIsOtherSelect(e.target.checked);
                      setIsCarSelect(false);
                      setIsBikeSelect(false);
                      setIsTrainSelect(false);
                      setIsDealerCarSelect(false);
                      setIsBusSelect(false);
                      setIsJointVisitSelect(false);
                      setIsTourSelected(false);
                    }}
                    // onClick={() => {
                    //   setIsCarSelect(false);
                    //   setIsBikeSelect(false);
                    // }}
                    type="checkbox"
                    name="other"
                    disabled={isPunchIn && true}
                  />
                  Other
                </label>
              </div>

              {showMeterInput ? (
                <input
                  type="number"
                  placeholder="Meter Reading"
                  className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-blue-light mt-3"
                  value={odometerDetails.meterReading}
                  onChange={(e) =>
                    setOdometerDetails({
                      ...odometerDetails,
                      meterReading: e.target.value,
                    })
                  }
                />
              ) : (
                ""
              )}

              <textarea
                type="text"
                className="w-full p-1  mt-3 border-[1px] text-sm focus:outline-none focus:border-blue-light border-gray-300 rounded-[4px] "
                cols="30"
                rows="2"
                placeholder="Remark"
                value={odometerDetails.remark}
                onChange={(e) =>
                  setOdometerDetails({
                    ...odometerDetails,
                    remark: e.target.value,
                  })
                }
              />
            </div>

            <div className="">
              <div className="flex gap-4 justify-end mt-1">
                <div className="flex justify-end items-center gap-2">
                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={crossIcon}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => {
                        handleResetOdometerMobile();
                      }}
                    />
                  </div>
                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={resetLogo}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => {
                        // handleRetake();
                        handleOpenCamera();
                      }}
                    />
                  </div>
                  <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                    <img
                      src={submitLogo}
                      alt="submit-logo"
                      className="w-[18px]  "
                      onClick={() => handleOdometerSubmit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isCameraModalOpenMobile ? (
        <div
          onClick={() => setIsCameraModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {/*Odometer Image capture  desktop🚀🚀🚀 */}
      {isCameraModalOpen && (
        <section className="bg-white px-4 py-4 absolute z-20 h-[80%] w-[96dvw] text-center  left-[50%] top-[12%] mr-0 mb-0 ml-[-48vw] border-[1px] rounded-[10px] border-gray-300 shadow-md overflow-auto">
          <div className="relative">
            <div className="flex gap-2 mb-2">
              <div className="bg-[#FF971A] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Add Odometer Picture</h1>
              </div>
            </div>

            {/* webcam  */}
            <div>
              <div>
                {picture == "" ? (
                  <div className="p-2 border-[1px]  w-full  border-gray-300 rounded-md mt-3">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      width={100 + "%"}
                      height={100 + "%"}
                      // height={window.innerHeight}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        // width: 300,
                        screenshotQuality: 10,
                        height: 200,
                        facingMode,
                      }}
                      capture
                      className="w-full"
                    />
                  </div>
                ) : (
                  <div className="p-2 border-[1px] border-gray-300 rounded-md">
                    <img src={picture} className="w-full h-[250px]" />
                  </div>
                )}
              </div>
              <div className="flex justify-end"></div>
            </div>

            <div className="">
              <input
                type="number"
                placeholder="Meter Reading"
                className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px]  focus:outline-none focus:border-orange-light mt-3"
                value={odometerDetails.meterReading}
                onChange={(e) =>
                  setOdometerDetails({
                    ...odometerDetails,
                    meterReading: e.target.value,
                  })
                }
              />
              <textarea
                type="text"
                className="w-full p-1  mt-3 border-[1px] text-sm focus:outline-none focus:border-orange-light border-gray-300 rounded-[4px] "
                cols="30"
                rows="2"
                placeholder="Remark"
                value={odometerDetails.remark}
                onChange={(e) =>
                  setOdometerDetails({
                    ...odometerDetails,
                    remark: e.target.value,
                  })
                }
              />
            </div>

            <div className="">
              <div className="flex gap-4 justify-end mt-1">
                <div className="flex justify-end items-center gap-2">
                  {picture != "" ? (
                    <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center cursor-pointer">
                      <img
                        src={resetLogo}
                        alt="submit-logo"
                        className="w-4"
                        onClick={(e) => {
                          e.preventDefault();
                          setPicture("");
                        }}
                      />
                    </div>
                  ) : (
                    <div className=" flex gap-2">
                      <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                        <img
                          src={cameraSwitch}
                          alt="submit-logo"
                          className="w-[18px] cursor-pointer "
                          onClick={(e) => {
                            e.preventDefault();
                            if (facingMode === "environment") {
                              setFacingMode("user");
                            }
                            if (facingMode === "user") {
                              setFacingMode("environment");
                            }
                          }}
                        />
                      </div>
                      <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                        <img
                          src={cameraCapture}
                          alt="submit-logo"
                          className="w-[18px]  cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            capture();
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={crossIcon}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => {
                        handleResetOdometer();
                      }}
                    />
                  </div>

                  <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                    <img
                      src={submitLogo}
                      alt="submit-logo"
                      className="w-[18px]  "
                      onClick={() => handleOdometerSubmit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isCameraModalOpen ? (
        <div
          onClick={() => setIsCameraModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Attendance;
