// import React, { useEffect, useRef, useState } from "react";
// import locationIcon from "../../assets/locationIcon.svg";
// import markerIcon from "../../assets/markerIcon.svg";

// import {
//   useJsApiLoader,
//   GoogleMap,
//   Marker,
//   Autocomplete,
//   DirectionsRenderer,
// } from "@react-google-maps/api";

// const center = { lat: 48.8584, lng: 2.2945 };

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };

// function MapRoute() {
//   const { isLoaded } = useJsApiLoader({
//     googleMapsApiKey: process.env.REACT_APP_Google_Api_Key,
//     libraries: ["places"],
//   });

//   const [loadingMap, setLoadingMap] = useState(true);

//   const [map, setMap] = useState(/** @type google.maps.Map */ (null));
//   const [directionsResponse, setDirestionsResponse] = useState(null);
//   const [distance, setDistance] = useState("");
//   const [duration, setDuration] = useState("");

//   /** @type React.MutableRefObject<HTMLInputElement> */
//   const originRef = useRef(null);
//   /** @type React.MutableRefObject<HTMLInputElement> */
//   const destinationRef = useRef(null);

//   // Location
//   const [location, setLocation] = useState({
//     lat: 22.3672358,
//     lng: 88.5679253,
//   });

//   const getCurrenttLocation = () => {
//     let location = navigator.geolocation.getCurrentPosition((position) => {
//       // console.log(position.coords);

//       setLocation({
//         lat: position.coords.latitude,
//         lng: position.coords.longitude,
//       });
//       return position.coords;
//     });

//     let value = watchUserLocation();
//     console.log(value);

//     return location;
//   };

//   useEffect(() => {
//     getCurrenttLocation();
//   }, []);

//   //   useEffect(() => {
//   //     let interval = setInterval(() => {
//   //       getCurrenttLocation();
//   //     }, 10000);

//   //     return () => {
//   //       clearInterval(interval);
//   //     };
//   //   }, []);

//   function watchUserLocation(callback) {
//     if (!navigator.geolocation) {
//       console.error("Geolocation is not supported by this browser.");
//       return;
//     }

//     const options = {
//       enableHighAccuracy: true,
//       maximumAge: 0,
//       timeout: 5000,
//     };

//     const watchId = navigator.geolocation.watchPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const location = {
//           latitude: latitude,
//           longitude: longitude,
//         };

//         console.log(location);

//         // Call the callback function with the updated location.
//         // callback(location);
//       },
//       (error) => {
//         console.error("Error watching user's location: ", error);
//       },
//       options
//     );

//     // Return the watchId so that it can be used to clear the watch later if needed.
//     return watchId;
//   }

//   const onLoad = (map) => {
//     setMap(map);
//     // Once the map is loaded, set loadingMap state to false
//     setLoadingMap(false);
//   };

//   async function calculateRoute() {
//     if (originRef.current.value === "" || destinationRef.current.value === "")
//       return;
//     // eslint-disable-next-line no-undef
//     const directionService = new google.maps.DirectionsService();

//     const result = await directionService.route({
//       origin: originRef.current.value,
//       destination: destinationRef.current.value,
//       // eslint-disable-next-line no-undef
//       travelMode: google.maps.TravelMode.DRIVING,
//     });

//     setDirestionsResponse(result);
//     setDistance(result.routes[0].legs[0].distance.text);
//     setDuration(result.routes[0].legs[0].duration.text);
//   }

//   function clearRoutes() {
//     setDirestionsResponse("");
//     setDistance("");
//     setDuration("");
//     originRef.current.value = "";
//     destinationRef.current.value = "";
//   }

//   if (!isLoaded || loadingMap) {
//     // Check both isLoaded and loadingMap states before rendering the map
//     return (
//       <div>
//         <p>Map Loading...</p>
//       </div>
//     );
//   }
//   return (
//     <div>
//       <div className="bg-[#fafafa] h-screen  w-full py-[17px] px-[13px] overflow-auto">
//         <div className="bg-white h-full py-4 px-3 rounded-[10px] shadow-6xl border-[1px] border-gray-300">
//           <div>
//             <div className="flex flex-col gap-3 ">
//               <Autocomplete
//                 bounds={
//                   new window.google.maps.LatLngBounds(
//                     { lat: location.lat, lng: location.lng },
//                     { lat: location.lat, lng: location.lng }
//                   )
//                 }
//               >
//                 <input
//                   ref={originRef}
//                   type="text"
//                   placeholder="Origin"
//                   className="border-[1px] w-full border-gray-300 py-1 px-2 rounded-md  focus:outline-none focus:border-orange-light"
//                 />
//               </Autocomplete>
//               <Autocomplete>
//                 <input
//                   ref={destinationRef}
//                   type="text"
//                   placeholder="Destination"
//                   className="border-[1px] w-full border-gray-300 py-1 px-2 rounded-md  focus:outline-none focus:border-orange-light"
//                 />
//               </Autocomplete>
//               <div className="space-x-4 flex items-center">
//                 <button
//                   type="submit"
//                   onClick={calculateRoute}
//                   className="bg-green-500 px-2 py-2 text-white text-sm rounded-md"
//                 >
//                   Calculate Route
//                 </button>
//                 <button
//                   onClick={clearRoutes}
//                   className="bg-red-500 px-4 py-2 text-white text-sm rounded-md"
//                 >
//                   Reset
//                 </button>

//                 <button onClick={() => map.panTo(location)}>
//                   <img src={locationIcon} className="w-7" />
//                 </button>
//               </div>
//             </div>

//             <div className="flex text-sm gap-3 mt-3">
//               <div>
//                 <p>Total Distance: {distance}</p>
//               </div>

//               <div>
//                 <p>Total Duration: {duration}</p>
//               </div>
//             </div>
//           </div>
//           {/* google maps */}
//           <section className="mt-4">
//             <GoogleMap
//               mapContainerStyle={containerStyle}
//               center={location}
//               zoom={15}
//               options={{
//                 zoomControl: false,
//                 streetViewControl: false,
//                 mapTypeControl: false,
//                 fullscreenControl: false,
//               }}
//               onLoad={onLoad}
//             >
//               {console.log("marker position", location)}
//               <Marker position={location} />
//               {/* {map && <Marker position={location} />} */}
//               {/* {isLoaded && <Marker position={location} />} */}

//               {/* {directionsResponse && (
//                 <DirectionsRenderer directions={directionsResponse} />
//               )} */}
//             </GoogleMap>
//           </section>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MapRoute;

import React, { useEffect, useRef, useState } from "react";
import locationIcon from "../../assets/locationIcon.svg";
import markerIcon from "../../assets/locationCircleIcon (2).svg";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import axios from "axios";
import config from "../Utils/config";

const center = { lat: 48.8584, lng: 2.2945 };

const containerStyle = {
  width: "100%",
  height: "400px",
};

function MapRoute() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_Google_Api_Key,
    libraries: ["places"],
  });

  const phoneNum = localStorage.getItem("superonUserPhNum");
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [displacementPath, setDisplacementPath] = useState([]);
  const [displacementDistance, setDisplacementDistance] = useState(0);

  const [origin, setOrigin] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirestionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef(null);
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef(null);

  // Location
  //   const [location, setLocation] = useState({
  //     lat: 22.3672358,
  //     lng: 88.5679245,
  //   });

  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const getCurrenttLocation = () => {
    let location = navigator.geolocation.getCurrentPosition((position) => {
      if (map) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }

      // Create an option for "Your Location" with the user's current coordinates
      const yourLocationOption = {
        description: "Your Location",
        geometry: {
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        },
      };

      setAutocompleteOptions([yourLocationOption]);
    });

    return location;
  };

  function watchUserLocation() {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000,
    };

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        if (map) {
          setLocation({
            lat: latitude,
            lng: longitude,
          });
        }

        console.log(latitude, longitude);

        // Create an option for "Your Location" with the user's current coordinates
        const yourLocationOption = {
          description: "Your Location",
          geometry: {
            location: {
              lat: latitude,
              lng: longitude,
            },
          },
        };

        setAutocompleteOptions([yourLocationOption]);

        // Pan the map to the new location
        map.panTo({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error watching user's location: ", error);
      },
      options
    );

    // Return the watchId so that it can be used to clear the watch later if needed.
    return watchId;
  }

  async function calculateRoute() {
    console.log(origin);
    console.log(originRef.current.value);
    if (originRef.current.value === "" || destinationRef.current.value === "")
      return;
    // eslint-disable-next-line no-undef
    const directionService = new google.maps.DirectionsService();
    const result = await directionService.route({
      origin: originRef.current.value,
      //   origin: {
      //     lat: 22.3672358,
      //     lng: 88.5679245,
      //   },
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    console.log(result);

    // let location

    // setPolylineCoordinates(result.routes[0].overview_path);
    // setPolylineCoordinates((prevCoordinates) => [...prevCoordinates, location]);
    setDirestionsResponse(result);
    setDistance(result.routes[0].legs[0].distance.text);
    setDuration(result.routes[0].legs[0].duration.text);

    // Calculate displacement path
    const displacementPath = [
      {
        lat: result.routes[0].legs[0].start_location.lat(),
        lng: result.routes[0].legs[0].start_location.lng(),
      },
      {
        lat: result.routes[0].legs[0].end_location.lat(),
        lng: result.routes[0].legs[0].end_location.lng(),
      },
    ];

    const userLocations = [
      { lat: 22, lng: 88 },
      { lat: 23, lng: 89 },
      { lat: 24, lng: 89.342 },
      { lat: 24.22, lng: 89.232 },
    ];
    setDisplacementPath(userLocations);

    // Calculate straight line distance in kilometers
    const { lat: lat1, lng: lng1 } = result.routes[0].legs[0].start_location;
    const { lat: lat2, lng: lng2 } = result.routes[0].legs[0].end_location;
    const distance = calculateDistance(lat1, lng1, lat2, lng2);
    console.log(distance);
    setDisplacementDistance(distance);
  }

  //   useEffect(() => {
  //     if (directionsResponse) {
  //       const path = directionsResponse.routes[0].overview_path.map((point) => ({
  //         lat: point.lat(),
  //         lng: point.lng(),
  //       }));
  //       setDisplacementPath(path);
  //     }
  //   }, [directionsResponse]);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function clearRoutes() {
    setDirestionsResponse("");
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destinationRef.current.value = "";
    window.location.reload();
  }

  const submitCurrentLocation = async () => {
    let data = {
      mobile: phoneNum,
      lat: location.lat,
      lang: location.lng,
    };

    if (!(location.lat && location.lng)) return;

    try {
      let response = await axios.post(
        config.ipAddress + `/position/currentposition`,
        data
      );
      console.log(response);
    } catch (error) {}
  };

  useEffect(() => {
    console.log("location tracking...");
    getCurrenttLocation();
    // watchUserLocation();
  }, [isLoaded, map]);

  useEffect(() => {
    if (map) {
      watchUserLocation();
    }
  }, [isLoaded, map]);

  // Use useEffect to handle the map and marker rendering
  useEffect(() => {
    // Check if the map object is available
    submitCurrentLocation();
    if (map) {
      // Render the marker when the map is available
      const marker = new window.google.maps.Marker({
        icon: markerIcon,
        scale: 0.01,
        position: location,
        map: map,
      });

      // Cleanup the marker when the component is unmounted
      return () => {
        marker.setMap(null);
      };
    }
  }, [map, location]);

  if (!isLoaded) {
    return (
      <div>
        <p>Map Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="bg-[#fafafa] h-screen  w-full py-[17px] px-[13px] overflow-auto">
        <div className="bg-white h-full py-4 px-3 rounded-[10px] shadow-6xl border-[1px] border-gray-300">
          <div>
            <div className="flex flex-col gap-3 ">
              <Autocomplete
              // options={autocompleteOptions}
              // onLoad={(autocomplete) => {
              //   autocomplete.addListener("place_changed", () => {
              //     const place = autocomplete.getPlace();
              //     if (place.geometry) {
              //       setLocation({
              //         lat: place.geometry.location.lat(),
              //         lng: place.geometry.location.lng(),
              //       });
              //     }
              //   });
              // }}
              // onUnmount={(autocomplete) => {
              //   autocomplete.removeListener("place_changed", () => {});
              // }}
              // onFocus={() => {
              //   getCurrenttLocation();
              // }}
              >
                <input
                  ref={originRef}
                  type="text"
                  placeholder="Origin"
                  onChange={(e) => setOrigin(e.target.value)}
                  className="border-[1px] w-full border-gray-300 py-1 px-2 rounded-md  focus:outline-none focus:border-orange-light"
                />
              </Autocomplete>
              <Autocomplete>
                <input
                  ref={destinationRef}
                  type="text"
                  placeholder="Destination"
                  className="border-[1px] w-full border-gray-300 py-1 px-2 rounded-md  focus:outline-none focus:border-orange-light"
                />
              </Autocomplete>
              <div className="space-x-4 flex items-center">
                <button
                  type="submit"
                  onClick={() => calculateRoute()}
                  className="bg-green-500 px-2 py-2 text-white text-sm rounded-md"
                >
                  Calculate Route
                </button>
                <button
                  onClick={clearRoutes}
                  className="bg-red-500 px-4 py-2 text-white text-sm rounded-md"
                >
                  Reset
                </button>

                <button onClick={() => map.panTo(location)}>
                  <img src={locationIcon} className="w-7" />
                </button>
              </div>
            </div>

            <div className="flex text-sm gap-3 mt-3">
              <div>
                <p>Total Distance: {distance}</p>
              </div>

              <div>
                <p>Total Duration: {duration}</p>
              </div>

              {displacementDistance ? (
                <div>
                  <p>
                    Displacement Distance: {displacementDistance.toFixed(2)} km
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* google maps */}
          <section className="mt-4">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={location}
              zoom={15}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={(map) => setMap(map)}
            >
              {/* Check if map is available before rendering the Marker */}

              {/* {map && (
                <Marker
                  position={{ lat: 22, lng: 88 }}
                  icon={{ url: markerIcon, scale: 0.5 }}
                />
              )} */}

              {/* {polylineCoordinates.length > 0 && (
                <Polyline
                  path={polylineCoordinates}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    icons: [
                      {
                        icon: {
                          path: "M 0,-1 0,1",
                          strokeOpacity: 1,
                          scale: 4,
                        },
                        offset: "0",
                        repeat: "20px",
                      },
                    ],
                    zIndex: 1, // Set a lower zIndex for the polyline
                  }}
                />
              )} */}

              <div>
                Displacement Distance: {displacementDistance.toFixed(2)} km
              </div>

              {displacementPath.length > 0 && (
                <Polyline
                  path={displacementPath}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                  }}
                />
              )}

              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </section>
        </div>
      </div>
    </div>
  );
}

export default MapRoute;
