import React from "react";
import "./phone.css";

function PhoneInputField({ handlePhoneSubmit, number }) {
  const handleKeyDown = (event) => {
    const inputNumber = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputNumber.length <= 10) {
      number.setPhoneNumber({
        ...number.phoneNumber,
        second: inputNumber,
      });
    }
  };

  // const handleZoomIn = () => {
  //   const parent = document.getElementById("zoomable-input-parent");
  //   parent.style.transform = "scale(1.1)"; // Zoom in by 20%
  // };
  // const handleZoomOut = () => {
  //   const parent = document.getElementById("zoomable-input-parent");
  //   parent.style.transform = "scale(1)"; // Zoom in by 20%
  // };
  return (
    <>
      <div className="h-full  px-3">
        {/* <div className="pt-16 ">
          <h3 className="text-lg font-bold  ">ENTER PHONE NUMBER</h3>
          <p className="opacity-60 text-sm font-normal">
            Otp will be sent to this number
          </p>
        </div> */}

        <section className="h-full shadow-6xl bg-white mt-4 px-4 py-4 mb-4 border-[1px] border-gray-300 rounded-[10px]">
          <div className="flex gap-2  mt-5">
            <div className="bg-blue-light w-[2.5px] rounded-full"></div>
            <div className="">
              <h1 className="text-md font-semibold">ENTER PHONE NUMBER</h1>
              <p className="text-sm">Otp will be sent to this number</p>
            </div>
          </div>
          <div className="mt-8 flex" id="zoomable-input-parent ">
            <input
              type="tel"
              value={number.phoneNumber.first}
              className="border-solid border-2 text-center   w-14  py-4 px-2  rounded-l"
              disabled
            />
            <input
              type="tel"
              maxLength={10}
              value={number.phoneNumber.second}
              onChange={(e) => {
                number.setPhoneNumber({
                  ...number.phoneNumber,
                  second: e.target.value,
                });
              }}
              // onClick={handleZoomIn}
              // onBlur={handleZoomOut}
              placeholder="Enter Phone Number"
              className=" border-solid w-full  pl-3 border-2 border-l-0 focus:outline-none  focus:border-l-0  box-border  rounded-sm"
              onKeyDown={handleKeyDown}
            />
          </div>
          <div>
            <button
              onClick={() => handlePhoneSubmit()}
              className={
                number.phoneNumber.second.length === 10
                  ? "mt-5 rounded-sm w-full bg-blue-light h-[53px] text-center text-lg font-semibold  text-white"
                  : "mt-5 rounded-sm w-full bg-[#EDEEF2] h-[53px] text-center text-lg font-semibold  text-[#A6AAB5]"
              }
            >
              Get OTP
            </button>
          </div>
        </section>
      </div>
    </>
  );
}

export default PhoneInputField;
