import React from "react";
import downArrow from "../../assets/downArrow.png";

function Input({
  handleLable,
  placeholder,
  inputRef,
  inputSpanRef,
  lable,
  value,
  changeValue,
  userDetails,
  name,
  stateList,
  showStateList,
  setIsStateListOpen,
  pattern,
}) {
  return (
    <div className="relative">
      <input
        ref={inputRef}
        className="flex h-14 box-border w-full rounded-md border-solid border-2 border-gray-300   py-2 px-3  text-sm font-medium placeholder:text-gray-400 focus:outline-none   focus:border-blue-light  "
        type="text"
        placeholder={placeholder}
        onClick={() => handleLable()}
        value={value}
        onChange={(e) =>
          changeValue({ ...userDetails, [name]: e.target.value })
        }
        // onBlur={handleBlur}
        // pattern={pattern}
      ></input>

      {/* To show downarrow for state and city */}
      {name === "state" ? (
        <>
          <img
            src={downArrow}
            alt="down-arrow"
            className="absolute w-4 top-3 right-2"
          />
          {showStateList && (
            <div className="absolute bg-white w-[330px] h-[200px] p-4 border-2 border-indigo-400 z-20 left-3 top-10 overflow-auto">
              {stateList.length
                ? stateList.map((state, index) => {
                    return (
                      <React.Fragment key={index}>
                        <p
                          onClick={() => {
                            console.log("clicked");
                            changeValue({ ...userDetails, state: state });
                            // stateList.length = 0;
                            setIsStateListOpen(false);
                          }}
                        >
                          {state}
                        </p>
                        <hr />
                      </React.Fragment>
                    );
                  })
                : ""}
            </div>
          )}
        </>
      ) : (
        ""
      )}

      {name === "city" ? (
        <img
          src={downArrow}
          alt="down-arrow"
          className="absolute w-4 top-3 right-2"
        />
      ) : (
        ""
      )}

      <span
        ref={inputSpanRef}
        className="bg-[#fff] hidden text-sm  absolute z-10  -top-[9px] left-4"
      >
        {lable}
      </span>
    </div>
  );
}

export default Input;
