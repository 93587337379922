import axios from "axios";
import { useState, useEffect } from "react";
import config from "../Utils/config";

// Custom hook to fetch and return data as an array
const useCurrentLocations = (url) => {
  let phoneNum = localStorage.getItem("superonUserPhNum");

  const [data, setData] = useState([]);
  const [visitLocations, setVisitLocations] = useState([]);
  const [markerData, setMarkerData] = useState([]);

  useEffect(() => {
    // Fetch data from the provided URL
    const getUserLocations = async () => {
      let data = {
        // mobile: "8285849095",
        mobile: phoneNum,
        startDate: "",
      };

      try {
        let response = await axios.post(
          config.ipAddress + `/position/getcurrentposition`,
          data
        );
        console.log(response);

        // setData(response?.data?.data?.positions);

        let visits = response?.data?.data?.visits;
        let visitValue = visits.map((marker) => ({
          lat: Number(marker.lat),
          lng: Number(marker.lng),
        }));

        // filter unique coords

        // Assuming the original data is stored in the 'coordinates' array

        setVisitLocations(visitValue);

        let polyline = response?.data?.data?.positions;
        // console.log(markers);

        let value = polyline.map((marker) => ({
          lat: Number(marker.lat),
          lng: Number(marker.lng),
        }));
        console.log(value);

        // Function to check if two objects are equal (based on their lat and lng properties)
        const areCoordinatesEqual = (coord1, coord2) => {
          return coord1.lat === coord2.lat && coord1.lng === coord2.lng;
        };

        // Function to filter unique coordinates
        const filterUniqueCoordinates = (originalCoordinates) => {
          const uniqueCoordinates = [];
          for (const coord of originalCoordinates) {
            if (!uniqueCoordinates.some((c) => areCoordinatesEqual(c, coord))) {
              uniqueCoordinates.push(coord);
              // if (uniqueCoordinates.length === 25) {
              //   break; // Stop once we have 25 unique coordinates
              // }
            }
          }
          return uniqueCoordinates;
        };

        const filteredCoordinates = filterUniqueCoordinates(value);

        setData(value);

        let markers = response?.data?.data?.markers;

        let markersValue = markers.map((marker) => ({
          lat: Number(marker.lat),
          lng: Number(marker.lng),
          visitno: marker.visitno,
          address: marker.address,
          customername: marker.customername,
        }));
        setMarkerData(markersValue);
      } catch (error) {}
    };

    getUserLocations();

    const intervalId = setInterval(() => {
      getUserLocations();
    }, 600000);

    return () => {
      clearInterval(intervalId);
    };
  }, [url]);

  return [data, visitLocations, markerData];
};

export default useCurrentLocations;
